import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { HEADER_HEIGHT } from 'constants/theme';

// constants
import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

// theme

const StyledContainer = styled(ToastContainer)`
    &&&.Toastify__toast-container {
        &--top-right {
            top: calc(${HEADER_HEIGHT} + 12px);
            ${mediaQueries.tablet} {
                top: calc(${HEADER_HEIGHT} + 21px);
            }
        }
    }

    .Toastify {
        &__toast {
            min-height: 48px;
            border-radius: 16px;
            padding: 6px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
            background-color: ${color('surface-main')};
            border: 1px solid ${color('border-default')};
            margin: 0 16px;
        }

        &__toast-icon {
            margin-right: 10px;
        }

        &__toast--success {
            background-color: ${color('surface-secondary')};
            border: 1px solid ${color('surface-default')};

            svg {
                fill: ${color('success-default')};
            }
        }

        &__toast--error {
            background-color: ${color('critical-surface')};
            border: 1px solid ${color('critical-highlight')};

            svg {
                fill: ${color('critical-default')};
            }
        }

        &__toast-body {
            color: ${color('text-secondary')};
        }
    }
`;

export default StyledContainer;
