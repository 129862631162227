import { ActionEventAction, ElementEventAction, EventTriggerFixed } from 'wikr-core-analytics';

import { trackClick, trackEventFixed } from './mainTrackers';

export const sendAnalyticLoginHeaderClick = () => {
    trackClick({
        elementEvent: ElementEventAction.text,
        actionEvent: ActionEventAction.headerLogin,
    });
};

export const sendAnalyticLoginEmailClick = () => {
    trackClick({
        elementEvent: ElementEventAction.input,
        actionEvent: ActionEventAction.email,
    });
};

export const sendAnalyticLoginPasswordClick = () => {
    trackClick({
        elementEvent: ElementEventAction.input,
        actionEvent: ActionEventAction.password,
    });
};

export const sendAnalyticLoginButtonClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.login,
    });
};

export const sendAnalyticResetPasswordClick = () => {
    trackClick({
        elementEvent: ElementEventAction.text,
        actionEvent: ActionEventAction.resetPassword,
    });
};

export const sendAnalyticLoginSuccess = () => {
    trackEventFixed(EventTriggerFixed.loginSuccess);
};

export const sendAnalyticLoginError = () => {
    trackEventFixed(EventTriggerFixed.loginError, 'Email or password is invalid');
};
