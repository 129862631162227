import React from 'react';

// styles
import * as S from './styled';

import logo from 'assets/img/walkfit_loader.webp';

const PageLoader = () => {
    return (
        <S.Container>
            <S.LogoImage src={logo} alt="logo" />
        </S.Container>
    );
};

export default PageLoader;
