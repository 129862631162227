import styled, { css } from 'styled-components';

// theme
import { color } from 'theme/selectors';

export const SideBarWrapper = styled.div<{ $isOpen: boolean }>`
    position: fixed;
    top: 57px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 56px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    z-index: 10000;

    ${({ $isOpen }) =>
        $isOpen &&
        css`
            opacity: 1;
            visibility: visible;

            ${SideBarOverlay} {
                opacity: 0.8;
            }

            ${SideBarMenu} {
                left: 0;
            }
        `}
`;

export const SideBarOverlay = styled.div`
    width: 100vw;
    height: 100vh;
    background: ${color('surface-main')};
    opacity: 0;
    transition: opacity 0.4s ease;
`;

export const SideBarMenu = styled.div`
    position: absolute;
    top: 0;
    left: -100%;
    display: flex;
    flex-direction: column;
    width: 260px;
    height: 100%;
    padding: 32px 16px;
    box-shadow: 0 4px 12px rgb(0 0 0 / 12%);
    background: ${color('surface-main')};
    transition: all 0.3s;
    overflow: scroll;
`;

export const Hr = styled.div`
    margin-bottom: 24px;
    width: 100%;
    min-height: 1px;
    background-color: ${color('border-default')};
`;

export const NavButton = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    background: transparent;
    cursor: pointer;
    margin-bottom: 32px;
    text-align: left;

    > p:hover {
        color: ${color('text-main')};
    }

    & img {
        margin-left: 8px;
    }
`;

export const RedDot = styled.div`
    width: 10px;
    height: 10px;
    margin-left: 9px;
    border-radius: 100%;
    background-color: ${color('discount')};
`;
