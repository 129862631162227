import { useRouteError, isRouteErrorResponse } from 'react-router';

const RootBoundary = () => {
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        // eslint-disable-next-line no-console
        console.warn('ROUTER ERROR');

        if (error.status === 404) {
            // eslint-disable-next-line no-console
            console.warn('ROUTER | PAGE NOT FOUND');
        }
    }

    return null;
};

export default RootBoundary;
