import React from 'react';

import Skeleton from 'components/Skeleton';

import * as S from './styled';

import { InstallAppButtonProps } from './types';

const StoreButton: React.FC<InstallAppButtonProps> = ({
    dataLocator,
    isLoading,
    deepLinkUrl,
    isIOS,
    isMobile = false,
    onClick,
}) => {
    const type = isIOS ? 'apple' : 'google';

    return (
        <S.Wrapper data-locator={dataLocator}>
            {isLoading ? (
                <Skeleton />
            ) : (
                <div onClick={onClick}>
                    <S.StoreBtn $isMobile={Boolean(isMobile)} link={deepLinkUrl as string} type={type} />
                </div>
            )}
        </S.Wrapper>
    );
};

export default StoreButton;
