import { Loader } from 'wikr-core-components';
import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import useDeleteAccount from 'hooks/common/useDeleteAccount';
import { useModals } from 'hooks';

import OutlinedButton from 'components/OutlinedButton';
import Modal from 'components/Modal/Modal';
import { Box, Button, Input, Text } from 'components/library';

import * as S from './styled';

import themeConfig from 'themeConfig';

const phrase = 'DELETE';

const DeleteAccountModalContainer = () => {
    const { t } = useTranslation();
    const { theme } = themeConfig;
    const { closeModal } = useModals();
    const { isLoading, onDeleteAccount } = useDeleteAccount();
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [confirmInput, setConfirmInput] = useState<string>('');
    const label = `${t('account.settings.deleteAccount.type')} 'DELETE'`;
    const isConfirmed = confirmInput === phrase;

    useEffect(() => {
        if (confirmInput && !isConfirmed && !showWarning) {
            setShowWarning(true);
        }
        if (!confirmInput) {
            setShowWarning(false);
        }
    }, [confirmInput]);

    return (
        <Modal isOpen onClose={closeModal}>
            <Box paddingTop={20} paddingBottom={20} paddingX={16}>
                <Text type="h5" bold center mb={24} text={t('account.settings.deleteAccount.title')} />
                {!isLoading ? (
                    <>
                        <Text type="medium-text" center mb={24}>
                            <Trans
                                i18nKey="account.settings.deleteAccount.subtitle"
                                components={{
                                    1: <span style={{ textTransform: 'uppercase' }} />,
                                }}
                            />
                        </Text>
                        <Input
                            name="confirmDelete"
                            isFocus
                            label={label}
                            placeholder={label}
                            value={confirmInput}
                            onChange={(e) => {
                                setConfirmInput(e.target.value);
                            }}
                            theme={theme}
                            type="text"
                            errorStatus={showWarning}
                            successStatus={isConfirmed}
                            errorMessage={t('account.settings.deleteAccount.warning')}
                        />
                        <S.Footer>
                            <S.FooterButton>
                                <OutlinedButton
                                    size="small"
                                    onClick={closeModal}
                                    mb={20}
                                    text={t('basics.cancel')}
                                    dataLocator="obCancel"
                                />
                            </S.FooterButton>
                            <S.FooterButton>
                                <Button
                                    size="small"
                                    disabled={!isConfirmed}
                                    onClick={onDeleteAccount}
                                    mb={20}
                                    text="basics.delete"
                                    dataLocator="obContinue"
                                />
                            </S.FooterButton>
                        </S.Footer>
                    </>
                ) : (
                    <S.LoaderContainer>
                        <Loader size={24} color="text-secondary" />
                    </S.LoaderContainer>
                )}
            </Box>
        </Modal>
    );
};

export default DeleteAccountModalContainer;
