import styled from 'styled-components';

import { Box } from 'components/library';

export const ScanQrCodeWrapper = styled(Box)`
    width: 150px;
    height: 150px;

    > * {
        width: 100% !important;
        height: 100% !important;
    }
`;
