import { Trans } from 'react-i18next';
import React from 'react';

import { Text } from 'components/library';

import * as S from './styled';

import { ISubscriptionCardHeader } from '../types';

const SubscriptionCardHeader = ({ icon, heading, isExpired }: ISubscriptionCardHeader) => {
    return (
        <S.SubscriptionCardHeader $isExpired={isExpired}>
            <S.Name>
                <img src={icon} alt={heading} />
                <Text type="medium-text" color={isExpired ? 'text-secondary-subdued' : 'text-main'}>
                    <Trans>{heading}</Trans>
                </Text>
            </S.Name>
        </S.SubscriptionCardHeader>
    );
};

export default SubscriptionCardHeader;
