import React, { FC } from 'react';

import * as S from './styled';

interface BurgerProps {
    isActive: boolean;
    onClick: () => void;
    isEnableInfoIndicator: boolean;
}

// isEnableInfoIndicator - have guides or courses
const Burger: FC<BurgerProps> = ({ isActive, onClick, isEnableInfoIndicator }) => (
    <S.Burger
        onClick={onClick}
        data-locator={isActive ? 'burgerCloseMenuBtn' : 'burgerOpenMenuBtn'}
        $isActive={isActive}
    >
        <S.Glyph />
        {!isActive && isEnableInfoIndicator && <S.RedDot />}
    </S.Burger>
);

export default Burger;
