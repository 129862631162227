import styled from 'styled-components';

import bg from './img/bg.png';

import { color } from 'theme/selectors';

export const HelpButton = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding: 28px 16px;
    border-radius: 12px;
    background: center / cover no-repeat url(${bg}), ${color('surface-secondary')};

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

export const FaqLink = styled.a`
    display: flex;
    align-items: center;
    height: 32px;
    padding: 4px 12px;
    background: ${color('primary-default')};
    border-radius: 10px;
    z-index: 10;
`;
