import ReactModal from 'react-modal';
import React, { memo } from 'react';

import { Cross } from 'components/Icon';
import BackButton from 'components/BackButton';

import * as S from './styled';

import { IModal } from 'types/modals';

ReactModal.defaultStyles = {};

const Modal = ({
    children,
    onClose,
    closeOnOverlayClick,
    withCloseButton,
    fullscreen,
    dataLocator,
    customStyles,
    closeBtnLocator,
    ...reactModalProps
}: IModal) => (
    <ReactModal
        shouldCloseOnOverlayClick={Boolean(closeOnOverlayClick)}
        shouldCloseOnEsc={false}
        contentLabel="Modal"
        contentElement={(props, content) => (
            <S.Content {...props} $fullscreen={Boolean(fullscreen)} data-locator={dataLocator}>
                {withCloseButton && (
                    <S.CloseButton onClick={onClose} data-locator={closeBtnLocator}>
                        <BackButton
                            onClick={onClose}
                            badge={() => <Cross color="text-main" />}
                            data-locator={closeBtnLocator}
                            backgroundColor="surface-secondary"
                        />
                    </S.CloseButton>
                )}
                {content}
            </S.Content>
        )}
        overlayElement={(props, contentElement) => <S.Overlay {...props}>{contentElement}</S.Overlay>}
        style={customStyles}
        ariaHideApp={false}
        {...reactModalProps}
    >
        {children}
    </ReactModal>
);

export default memo(Modal);
