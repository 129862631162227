import { FC } from 'react';

import { sendAnalyticDownloadGuideClick } from 'services/analytics';

import { Text } from 'components/library';
import { Download } from 'components/Icon';

import * as S from './styled';

interface GuideProps {
    img?: string;
    link?: string;
    title: string;
}

const Guide: FC<GuideProps> = ({ title, img, link }) => {
    const handleDownloadClick = () => title && sendAnalyticDownloadGuideClick(title);

    return (
        <S.Container>
            {img && <S.GuideImg alt="guide-img" src={img} />}
            <S.GuideTitleContainer>
                <Text center={!img} type="medium-text" text={title} />
            </S.GuideTitleContainer>
            {link && (
                <S.DownloadGuideLink
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleDownloadClick}
                >
                    <Download />
                </S.DownloadGuideLink>
            )}
        </S.Container>
    );
};

export default Guide;
