import { useEffect } from 'react';

import api from 'api';

import { getCurrentUserError, getCurrentUserSuccess } from 'store/user/actions';
import { notifyError } from 'store/notifications/actions';
import { selectGuidesInfo } from 'store/guides/selectors';
import { fetchGuides, setIsShowGuidesNotification } from 'store/guides/actions';

import { setUserProperties } from 'services/analytics/userProperties';

import { useDispatch, useSelector } from 'hooks';

import { isUserLoggedIn } from 'helpers/verifiers';

export const useFetchUserData = () => {
    const dispatch = useDispatch();

    const { isShowGuidesNotification } = useSelector(selectGuidesInfo);

    const isAuthenticated = isUserLoggedIn();

    const getUserInfo = async () => {
        return await api.user.getUser();
    };

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchGuides());

            if (isShowGuidesNotification === null) {
                dispatch(setIsShowGuidesNotification(true));
            }

            getUserInfo()
                .then((userData) => {
                    dispatch(getCurrentUserSuccess(userData));

                    setUserProperties(userData);
                })

                .catch(({ error }) => {
                    dispatch(notifyError('message.error.somethingWentWrong'));
                    dispatch(getCurrentUserError(error));
                });
        }
    }, [isAuthenticated]);
};
