import { Gender } from 'types/store/userStore';

// user
export interface IUserData {
    date_of_birth?: string;
    age?: string;
    email: string;
    gender: Gender;
    height: number;
    weight: number;
    target_weight: number;
}

export interface IUserUpdateData extends Partial<IUserData> {
    country?: null | string;
    last_name?: null | string;
    units?: string;
    sendpulse_mailing_active?: boolean;
    password?: string;
    phone?: string;
    name?: null | string;
}

export type IChangePasswordType = {
    old_password: string;
    new_password: string;
};

export interface IUserInfoResponse extends IUserData {
    user_id: number;
    token: string;
    name: null | string;
    last_name: null | string;
    is_paid: false;
    language: string;
    country: null | string;
    product_list: null | {
        [product: string]: boolean;
    };
    units?: UnitTypes;
    localization: string;
    target_zone: string[];
    test_name?: string;
    ip_address: string;
    fitness_level: string;
    phone?: string;
    urlParams?: object | null;
    branch_name?: string;
    target_bodytype?: string;
    current_bodytype?: string;
    goal?: string;
    activity_level?: string;
    domain_name?: string;
    app_link?: string;
    is_password?: boolean;
    payment_method?: null | 'paysafecard' | 'paypal_vault';
}

export interface ISignInData {
    password: string;
    email: string;
}

// other
export interface IDeepLinkData {
    url: string;
}

export interface IHashedEmailResp {
    data: string;
}

export enum UnitTypes {
    Imperial = 'imperial',
    Metric = 'metric',
}
