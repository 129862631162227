import { ISubscriptionCancelResponse } from 'api/types/response';
import { ICancelSubscriptionRequest } from 'api/types/request';

import { ISubscription } from 'types/subscription';

import Base from './Base';

class SubscriptionsApi extends Base {
    getSubscriptions = async <Response = ISubscription>(): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.get<Response>('user/subscription');
    };

    setReminder = async (payload: { external_id: string }): Promise<string> => {
        return await this.apiClient.private.post('user/subscription/reminder', payload);
    };

    unsubscribe = async <Data = ICancelSubscriptionRequest, Response = ISubscriptionCancelResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.post<Data, Response>('payment/subscription/cancel', payload);
    };
}

export default SubscriptionsApi;
