import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import React from 'react';

import { selectCurrentUser } from 'store/user/selectors';

import { STEPS_DESKTOP, STEPS_MOBILE } from 'constants/downloadSteps';

import { getWindowSize } from 'helpers/utils';

import Step from '../Step/Step';

const DownloadSteps = () => {
    const isDesktop = getWindowSize();
    const { email } = useSelector(selectCurrentUser);
    const steps = isDesktop ? STEPS_DESKTOP : STEPS_MOBILE;

    return (
        <>
            {steps.map((stepKey, index) => (
                <Step key={stepKey} id={index + 1}>
                    <Trans i18nKey={stepKey} components={[<strong key="1" />]} values={{ email }} />
                </Step>
            ))}
        </>
    );
};

export default DownloadSteps;
