import styled, { keyframes } from 'styled-components';

import themeConfig from 'themeConfig';
import { color } from 'theme/selectors';

const { theme } = themeConfig;
const pulsar = keyframes`
    0% {
      box-shadow: 0 0 0 0 ${theme['primary-default']}
    }

    100% {
        box-shadow: 0 0 0 14px transparent;
    }
`;

export const DownloadButton = styled.a<{ $centered: boolean }>`
    display: flex;
    justify-content: ${({ $centered }) => ($centered ? 'center' : 'space-between')};
    align-items: center;
    width: 100%;
    height: 64px;
    margin: 0 auto;
    border-radius: 12px;
    padding: 0 20px;
    background-color: ${color('primary-default')};
    font-family: inherit;
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: ${color('surface-main')};
    animation: ${pulsar} 2s infinite;
`;
