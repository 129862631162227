import { useTranslation } from 'react-i18next';
import React from 'react';

import { setCancellationCandidate } from 'store/subscriptions/actions';
import { openModal } from 'store/modals/actions';

import { SUBSCRIPTION_PENDING_FAILED } from 'constants/payments';

import useSubscriptionsCount from 'hooks/subscriptions/useSubscriptionCount';
import { useSubscriptions, useDispatch } from 'hooks';

import Skeleton from 'components/Skeleton';
import { Box, Text } from 'components/library';
import PaymentFailedCard from './components/SubscriptionCard/components/PaymentFailedCard';
import { SubscriptionCard, SubscriptionHelpButton } from './components';

import { CancelCandidate, ISubscription } from 'types/subscription';
import { ModalName } from 'types/modals';

const SubscriptionsTab = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { isLoading, subscriptions } = useSubscriptions();
    const { activeCount, canceledCount } = useSubscriptionsCount(subscriptions);

    const onCancelSubscription = (candidate: CancelCandidate) => {
        dispatch(setCancellationCandidate(candidate));

        dispatch(openModal(ModalName.REASON_MODAL));
    };

    const displaySkeleton = isLoading && !subscriptions?.length;

    const failedFirstIndex = subscriptions.findIndex(
        (subscription) => subscription.state === SUBSCRIPTION_PENDING_FAILED
    );

    return (
        <Box paddingTop={40} paddingBottom={40}>
            {displaySkeleton ? (
                <Skeleton height={128} />
            ) : (
                <div>
                    {Boolean(activeCount) && (
                        <Text type="h6" mb={20}>
                            {t('subscription.text.activeCount', { count: activeCount })}
                        </Text>
                    )}
                    {Boolean(canceledCount) && !Boolean(activeCount) && (
                        <Text type="h6" mb={20}>
                            {t('subscription.text.canceledCount', { count: canceledCount })}
                        </Text>
                    )}
                    {subscriptions?.map((subscription: ISubscription, index) => {
                        if (index === failedFirstIndex) {
                            return <PaymentFailedCard key={subscription.id} />;
                        }

                        if (subscription.state !== SUBSCRIPTION_PENDING_FAILED) {
                            return (
                                <div key={subscription.id} data-locator={`subscription${index}`}>
                                    <div key={index} data-locator={`subscription${index}`}>
                                        <SubscriptionCard
                                            subscription={subscription}
                                            onCancelSubscription={onCancelSubscription}
                                        />
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
            )}
            <SubscriptionHelpButton />
        </Box>
    );
};

export default SubscriptionsTab;
