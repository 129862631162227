import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import React, { useEffect } from 'react';

import { PUBLIC } from 'router/constants/routes';

import { selectIsOpenSidebar } from 'store/uiEffects/selectors';

import { useFetchUserData, useSelector } from 'hooks';

import { isUserLoggedIn } from 'helpers/verifiers';

import Sidebar from 'components/Sidebar';
import { Header } from 'components/Header';

import { PageWrapper } from './styled';

import { LayoutProps } from '../types';

const CabinetLayout = ({ children, titleKey }: LayoutProps) => {
    if (!isUserLoggedIn()) return <Navigate to={PUBLIC.LOGIN.path} />;

    const isOpenSidebar = useSelector(selectIsOpenSidebar);

    useFetchUserData();

    const pageWrapper = document.querySelector('div[class*="PageWrapper"]') as Element;
    const scrollY = pageWrapper && window.getComputedStyle(pageWrapper).top;

    useEffect(() => {
        !isOpenSidebar && window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }, [isOpenSidebar]);

    const { t } = useTranslation();

    return (
        <PageWrapper $isOpenSidebar={isOpenSidebar}>
            <Helmet title={t(titleKey)} />
            <Header />
            <Sidebar />
            {children}
        </PageWrapper>
    );
};

export default CabinetLayout;
