import styled from 'styled-components';

import { color } from 'theme/selectors';

export const Circle = styled.div`
    font-weight: 700;
    width: 32px;
    height: 32px;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${color('primary-default')};
    margin-right: 16px;
`;
