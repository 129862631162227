import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import React, { Component } from 'react';

import sentry from 'services/Sentry/SentryInstance';

import GlobalErrorModal from 'modals/GlobalErrorModal';

import { IProps, IState } from './types';

class ErrorBoundary extends Component<IProps, IState> {
    public state: IState = {
        hasError: false,
        error: null,
    };

    public static getDerivedStateFromError(): IState {
        return { hasError: true };
    }

    public logError(error: Error) {
        sentry.logError(error, SENTRY_APP, ERROR_LEVELS.CRITICAL);
    }

    public componentDidCatch(error: Error) {
        this.setState({ error });

        this.logError(error);
    }

    public render() {
        if (this.state.hasError) {
            return <GlobalErrorModal isOpen={true} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
