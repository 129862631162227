import { Image } from 'wikr-core-components';
import styled, { keyframes } from 'styled-components';

import { color } from 'theme/selectors';

const logoAnimation = keyframes`
    0% {
        opacity: 0.1;
    }

    100% {
        opacity: 1;
    }
`;

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background: ${color('primary-default')};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const LogoImage = styled(Image)`
    width: 121px;
    height: auto;
    opacity: 0;
    animation: ${logoAnimation} 1s 500ms infinite alternate;
`;
