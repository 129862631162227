import * as action from './actions';

import * as actionTypes from './actionTypes';

import { GuidesState } from 'types/guide';
import { InferValueTypes } from 'types/commonTypes';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const initialState = {
    userGuides: [],
    isLoading: true,
    isError: false,
    isShowGuidesNotification: false,
};

const guidesReducer = (state: GuidesState = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.SET_LOADING_STATE:
            return { ...state, isLoading: action.payload };

        case actionTypes.SET_GUIDES:
            return { ...state, isLoading: false, userGuides: action.payload };

        case actionTypes.SET_GUIDES_IS_ERROR:
            return { ...state, isError: action.payload };

        case actionTypes.SET_IS_SHOW_GUIDES_NOTIFICATION:
            return { ...state, isShowGuidesNotification: action.payload };

        default:
            return state;
    }
};

export default guidesReducer;
