import { Colors } from 'wikr-core-components';
import { ButtonHTMLAttributes } from 'react';

import * as S from './styled';

import { ReactComponent as SVGArrow } from './assets/arrow-left-long.svg';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
    onClick?: () => void;
    withShadow?: boolean;
    size?: number;
    badge?: any;
    badgeColor?: string;
    backgroundColor?: Colors;
};

const BackButton = ({
    withShadow,
    onClick,
    size = 40,
    badge: Badge = SVGArrow,
    badgeColor = 'text-main',
    backgroundColor = 'surface-main',
}: Props) => {
    return (
        <S.RoundedButton
            $withShadow={Boolean(withShadow)}
            $size={size}
            onClick={onClick}
            $backgroundColor={backgroundColor}
        >
            <Badge color={badgeColor} />
        </S.RoundedButton>
    );
};

export default BackButton;
