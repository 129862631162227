import { all, takeLatest } from 'redux-saga/effects';

import { notify, notifyError, notifySuccess } from './actions';

import { DISPLAY_ERROR_NOTIFICATION, DISPLAY_DEFAULT_NOTIFICATION, DISPLAY_SUCCESS_NOTIFICATION } from './actionTypes';

import { NOTIFY_STATUSES } from 'constants/notifications';

import toast from 'services/toast';

const displayErrorNotification = ({ payload }: ReturnType<typeof notifyError>) => {
    toast({ message: payload, status: NOTIFY_STATUSES.ERROR });
};

const displaySuccessNotification = ({ payload }: ReturnType<typeof notifySuccess>) => {
    toast({ message: payload, status: NOTIFY_STATUSES.SUCCESS });
};

const displayNotification = ({ payload }: ReturnType<typeof notify>) => {
    toast({ message: payload, status: NOTIFY_STATUSES.DEFAULT });
};

export default function* watchAuth() {
    yield all([
        takeLatest(DISPLAY_ERROR_NOTIFICATION, displayErrorNotification),
        takeLatest(DISPLAY_DEFAULT_NOTIFICATION, displayNotification),
        takeLatest(DISPLAY_SUCCESS_NOTIFICATION, displaySuccessNotification),
    ]);
}
