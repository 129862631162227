import { getToFixedNumber } from './number';

const LBS_KG_COEFFICIENT = 2.2046;
const CM_INCH_COEFFICIENT = 2.54;
const FEET_INCH_COEFFICIENT = 12;

export const fromLbsToKg = (lbs: number): number => {
    return lbs / LBS_KG_COEFFICIENT;
};

export const fromKgToLbs = (kg: number): number => {
    return Math.round(kg * LBS_KG_COEFFICIENT);
};

export const fromFeetAndInchesToCentimeters = (ft: number, inch: number) => {
    const inchesTotal = ft * FEET_INCH_COEFFICIENT + inch;
    const cmTotal = getToFixedNumber(inchesTotal * CM_INCH_COEFFICIENT, 2);

    return cmTotal;
};

export const cmToFeetAndInch = (cm: number) => {
    const inchLength = cm / CM_INCH_COEFFICIENT;

    const feet = Math.trunc(inchLength / FEET_INCH_COEFFICIENT);
    const inch = Math.round(inchLength % FEET_INCH_COEFFICIENT);

    return { feet, inch };
};
