export default {
    videoLinks: {
        ios: {
            en: 'https://mobile.appscdn.io/%21VIDEO/MyCoachApp/Public/FC%28w%29/Ios/EN.webp',
            es: 'https://mobile.appscdn.io/%21VIDEO/MyCoachApp/Public/FC%28w%29/Ios/ES.webp',
            pt: 'https://mobile.appscdn.io/%21VIDEO/MyCoachApp/Public/FC%28w%29/Ios/PT.webp',
            it: 'https://mobile.appscdn.io/%21VIDEO/MyCoachApp/Public/FC%28w%29/Ios/IT.webp',
            de: 'https://mobile.appscdn.io/%21VIDEO/MyCoachApp/Public/FC%28w%29/Ios/DE.webp',
            fr: 'https://mobile.appscdn.io/%21VIDEO/MyCoachApp/Public/FC%28w%29/Ios/FR.webp',
            ko: 'https://mobile.appscdn.io/%21VIDEO/MyCoachApp/Public/FC%28w%29/Ios/KO.webp',
            ja: 'https://mobile.appscdn.io/%21VIDEO/MyCoachApp/Public/FC%28w%29/Ios/JA.webp',
        },
        android: {
            en: 'https://mobile.appscdn.io/%21VIDEO/MyCoachApp/Public/FC%28w%29/Android/EN.webp',
            es: 'https://mobile.appscdn.io/%21VIDEO/MyCoachApp/Public/FC%28w%29/Android/ES.webp',
            pt: 'https://mobile.appscdn.io/%21VIDEO/MyCoachApp/Public/FC%28w%29/Android/PT.webp',
            it: 'https://mobile.appscdn.io/%21VIDEO/MyCoachApp/Public/FC%28w%29/Android/IT.webp',
            de: 'https://mobile.appscdn.io/%21VIDEO/MyCoachApp/Public/FC%28w%29/Android/DE.webp',
            fr: 'https://mobile.appscdn.io/%21VIDEO/MyCoachApp/Public/FC%28w%29/Android/FR.webp',
            ko: 'https://mobile.appscdn.io/%21VIDEO/MyCoachApp/Public/FC%28w%29/Android/KO.webp',
            ja: 'https://mobile.appscdn.io/%21VIDEO/MyCoachApp/Public/FC%28w%29/Android/JA.webp',
        },
    },
};
