import { selectIsCloseInformationModal } from 'store/user/selectors';
import { setIsCloseInformationModal } from 'store/user/actions';
import { selectIsOpenSidebar } from 'store/uiEffects/selectors';
import { setIsOpenSideBar } from 'store/uiEffects/actions';

import { useDispatch, useSelector } from 'hooks/store';

const useSidebarControl = () => {
    const dispatch = useDispatch();

    const isOpenSidebar = useSelector(selectIsOpenSidebar);
    const isCloseInformationModal = useSelector(selectIsCloseInformationModal);

    const open = () => {
        dispatch(setIsOpenSideBar(true));
    };

    const toggle = () => {
        dispatch(setIsOpenSideBar(!isOpenSidebar));

        if (!isCloseInformationModal) dispatch(setIsCloseInformationModal(true));
    };

    const close = () => {
        dispatch(setIsOpenSideBar(false));
    };

    return { open, close, toggle, isOpen: isOpenSidebar };
};

export default useSidebarControl;
