import { useTranslation, Trans } from 'react-i18next';
import React, { useState, useRef } from 'react';

import { periodsList } from './constants';

import { sendAnalyticSubscriptionKeepClick } from 'services/analytics';

import isMobile from 'helpers/isMobile';

import OverlineButton from 'components/OverlineButton';
import Modal from 'components/Modal';
import { Text, Button, Box } from 'components/library';

import * as S from './styled';

import { IFreezePeriodModal } from 'types/modals';

const FreezePeriodModal = ({ onSubmit, onDiscard, onClose }: IFreezePeriodModal) => {
    const { t } = useTranslation();
    const cancellationReasonBtn = useRef<null | HTMLDivElement>(null);

    const [freezePeriod, setFreezePeriod] = useState<string | null>(null);

    const handleSelectReason = (shortKey: string) => () => {
        setFreezePeriod(shortKey);

        cancellationReasonBtn.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    };

    const handleDiscard = () => {
        setFreezePeriod(null);
        sendAnalyticSubscriptionKeepClick();

        onDiscard();
    };

    return (
        <Modal isOpen onClose={onClose} fullscreen={isMobile.any()}>
            <Box paddingTop={20} paddingBottom={20}>
                <Text type="h5" center mb={24}>
                    <Trans
                        i18nKey="subscription.whatFreezePeriod.title"
                        components={{ 1: <span style={{ color: '#1975FF' }} /> }}
                    />
                </Text>
                <Box mb={32}>
                    {periodsList.map(({ title, shortKey }) => (
                        <S.PeriodItem
                            key={shortKey}
                            onClick={handleSelectReason(shortKey)}
                            $isSelected={shortKey === freezePeriod}
                            data-locator="freezeSubPeriodOption"
                        >
                            <Text bold text={t(title)} />
                        </S.PeriodItem>
                    ))}
                </Box>

                <div ref={cancellationReasonBtn}>
                    <Button
                        disabled={!freezePeriod}
                        onClick={() => freezePeriod && onSubmit(freezePeriod)}
                        mb={20}
                        text="basics.continue"
                        dataLocator="obContinue"
                    />
                </div>
                <OverlineButton
                    text={t('subscription.cancelDialog.discard')}
                    onClick={handleDiscard}
                    dataLocator="keepSubBtn"
                />
            </Box>
        </Modal>
    );
};

export default FreezePeriodModal;
