import { Trans, useTranslation } from 'react-i18next';
import React from 'react';

import { isEmpty } from 'helpers/dataStructuresUtils';
import { getSubscriptionTitle } from '../helpers';

import { Text } from 'components/library';

import * as S from './styled';

import { ReactComponent as ClockIcon } from '../img/clock.svg';

import { IPendingSubscription } from '../types';

import SubscriptionCardWrapper from './SubscriptionCardWrapper';
import StatusIcon from './StatusIcon';
import SubscriptionCardHeader from './SabscriptionCardHeader';

const PendingSubscription = ({
    tabIcon,
    heading,
    state,
    subscriptionPeriod,
    upcomingProduct,
    subscriptionType,
}: IPendingSubscription) => {
    const { t } = useTranslation();

    const paymentLabel = getSubscriptionTitle(subscriptionPeriod, false);
    const upcomingLabel = upcomingProduct && getSubscriptionTitle(upcomingProduct.subscription_period, false);

    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader icon={tabIcon} heading={heading} />
            <S.Status>
                <ClockIcon />
                <Text type="caption" color="text-secondary" text={t(`subscription.status.${state}`)} />
                <Text type="caption" color="text-secondary" text=":" />
            </S.Status>
            <Text mb={20}>
                <Trans
                    i18nKey="subscription.period"
                    values={{
                        count: paymentLabel?.count,
                        context: paymentLabel?.context,
                        subscriptionType,
                    }}
                />
            </Text>
            <Text bold color="secondary-active" mb={4} text={t('subscription.pendingSubscription.title')} />
            <Text type="small-text" color="text-secondary" mb={22} text={t('subscription.pendingSubscription.text')} />
            {upcomingProduct && !isEmpty(upcomingProduct) && (
                <>
                    <S.Status>
                        <StatusIcon isActive={false} />
                        <Text type="caption" color="text-secondary" text={t('subscription.status.upcoming')} />
                        <Text type="caption" color="text-secondary" text=":" />
                    </S.Status>
                    <Text>
                        <Trans
                            i18nKey="subscription.period"
                            values={{
                                count: upcomingLabel?.count,
                                context: upcomingLabel?.context,
                                subscriptionType: t('subscription.type.subscription'),
                            }}
                        />
                    </Text>
                </>
            )}
        </SubscriptionCardWrapper>
    );
};

export default PendingSubscription;
