// assets
import styled from 'styled-components';

import { ReactComponent as SVGLogo } from './assets/projectLogo.svg';
import { ReactComponent as SVGLogoBig } from './assets/bigProjectLogo.svg';

export const LogoContainer = styled.div<{ $height?: number; $isAuthenticated?: boolean }>`
    display: flex;
    align-items: center;
    gap: 8px;
    height: ${({ $height }) => ($height ? `${$height}px` : 'auto')};
    cursor: ${({ $isAuthenticated }) => ($isAuthenticated ? 'pointer' : 'default')};
`;

export const LogoImage = styled(SVGLogo)`
    display: block;
    height: 100%;
    width: auto;
`;

export const LogoImageBig = styled(SVGLogoBig)`
    display: block;
    height: 100%;
    width: auto;
`;
