import workouts from './img/workouts.png';
import guide from './img/guide.png';
import courseAndGuide from './img/coursesAndGuide.png';
import course from './img/course.png';

export const configInformationModal = {
    course: { img: course, text: 'informationModal.text.course' },
    guide: { img: guide, text: 'informationModal.text.guide' },
    courseAndGuide: { img: courseAndGuide, text: 'informationModal.text.courseAndGuide' },
    workoutsAndGuide: { img: workouts, text: 'informationModal.text.workoutsAndGuide' },
    workoutsAndGuideAndCourses: { img: workouts, text: 'informationModal.text.workoutsAndGuideAndCourses' },
};
