import { Trans, useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import { Button, Text } from 'components/library';
import ContactUsLink from 'components/ContactUsLink';

import * as S from './styled';

import icon from './img/icon.webp';

import { CommonModalProps } from 'types/modals';

function GlobalErrorModal({ ...modalProps }: CommonModalProps) {
    const { t } = useTranslation();

    const handleReloadClick = () => window.location.reload();

    return (
        <Modal {...modalProps}>
            <S.Wrapper>
                <S.Icon src={icon} alt="error" />
                <Text type="h1" color="text-secondary" center mb={20}>
                    <Trans i18nKey="errorModal.title" />
                </Text>
                <Text mb={30}>
                    <Trans i18nKey="errorModal.description" />
                </Text>
                <Button text={t('basics.tryAgain')} onClick={handleReloadClick} mb={23} />
                <ContactUsLink color="text-secondary" />
            </S.Wrapper>
        </Modal>
    );
}

export default GlobalErrorModal;
