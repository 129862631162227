import React from 'react';

import { openModal } from 'store/modals/actions';

import { sendAnalyticFreezeReasonClick } from 'services/analytics/trackers/subscriptions';

import { useCancelSubscription, useDispatch } from 'hooks';

import CancellationReasonModal from 'modals/CancellationReasonModal';

import { ModalName } from 'types/modals';

const FreezeReasonModalContainer = () => {
    const dispatch = useDispatch();

    const { discardCancellation } = useCancelSubscription();

    const onSubmit = (reason: string) => {
        sendAnalyticFreezeReasonClick(reason);

        dispatch(openModal(ModalName.FREEZE_PERIOD_MODAL));
    };

    return (
        <CancellationReasonModal
            onSubmit={onSubmit}
            onDiscard={discardCancellation}
            onClose={discardCancellation}
            isFreezeSubscription
        />
    );
};

export default FreezeReasonModalContainer;
