import styled from 'styled-components';

// theme
import { color } from 'theme/selectors';

export const RadioLabel = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const Radio = styled.div`
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 12px;
    position: relative;
    border-radius: 50%;
    border: 2px solid ${color('border-default')};

    &:before {
        visibility: hidden;
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        background: ${color('primary-default')};
        transform: translate(-50%, -50%);
    }
`;

export const Input = styled.input`
    display: none;

    &:checked + ${RadioLabel} ${Radio} {
        border: 2px solid ${color('primary-default')};

        &:before {
            visibility: visible;
        }
    }
`;

export const RadioWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;
