import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import appConfig from 'appConfig';

import { PUBLIC, PRIVATE } from 'router/constants/routes';

import { createErrorSelector } from 'store/errors/selectors';
import { isAuthenticatedSelector } from 'store/auth/selectors';
import { SIGN_IN } from 'store/auth/actionTypes';
import { signInRequest } from 'store/auth/actions';

import {
    sendAnalyticLoginButtonClick,
    sendAnalyticLoginError,
    sendAnalyticLoginSuccess,
    sendAnalyticResetPasswordClick,
} from 'services/analytics';

import { useDispatch, useSelector } from 'hooks/store';

import { isEmpty } from 'helpers/dataStructuresUtils';

import { Text, Button, Box, Image } from 'components/library';
import FormikInput from 'components/FormikInput';

import { ForgotPasswordLink } from './styled';

import * as S from 'assets/styles/authStyled';
import loginImage from 'assets/img/loginImage.webp';

import { CONTENT } from './config';

import { LoginSchema } from './validationSchema';

type FormValue = {
    email: string;
    password: string;
};

const Login = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isFetching, setFetchingStatus] = useState(false);

    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const error = useSelector(createErrorSelector(SIGN_IN));
    const { redirectUrl } = useSelector((state) => state.auth);

    const successRedirectPath = redirectUrl || PRIVATE.MAIN.path;

    useEffect(() => {
        if (isAuthenticated) {
            navigate(successRedirectPath);
        }
    }, []);

    useEffect(() => {
        if (error?.message) {
            CONTENT.forEach((field) => setFieldError(field.name, error?.message));
        }
    }, [error]);

    const handleSuccessLogin = () => {
        sendAnalyticLoginSuccess();

        navigate(successRedirectPath);
    };

    const handleFailLogin = () => {
        sendAnalyticLoginError();

        CONTENT.forEach((field) => setFieldError(field.name, t('login.error.forbidden')));

        setFetchingStatus(false);
    };

    const {
        errors,
        dirty,
        values,
        initialValues,
        setFieldTouched,
        setFieldValue,
        touched,
        handleSubmit,
        setFieldError,
    } = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: LoginSchema,
        validateOnBlur: true,
        onSubmit: (userData: FormValue) => {
            setFetchingStatus(true);

            sendAnalyticLoginButtonClick();

            dispatch(
                signInRequest({
                    userData,
                    onSuccess: handleSuccessLogin,
                    onError: handleFailLogin,
                })
            );
        },
    });

    return (
        <S.AuthContainer>
            <S.AuthImage>
                <Image src={loginImage} alt="reset-password" isForceVisible />
            </S.AuthImage>
            <S.AuthForm>
                <form>
                    <Box paddingBottom={32} paddingX={16}>
                        <Text type="h5" bold center mb={12} text={t('login.title')} />
                        <Text center mb={4} text={t('login.subTitle')} />

                        <Text type="overline" bold center mb={20}>
                            <a href={appConfig.links.onboarding} data-locator="surveyButton">
                                {t('login.survey')}
                            </a>
                        </Text>
                        <Box mb={44}>
                            {CONTENT.map(({ name, label, type, icon, dataLocator, placeholder, analyticHandler }) => (
                                <FormikInput
                                    key={name}
                                    mb={32}
                                    type={type}
                                    field={name}
                                    label={label}
                                    setFieldTouched={setFieldTouched}
                                    initialValues={initialValues}
                                    setFieldValue={setFieldValue}
                                    onInputFocus={analyticHandler}
                                    touched={touched}
                                    values={values}
                                    errors={errors}
                                    leftIcon={icon}
                                    dataLocator={dataLocator}
                                    placeholder={placeholder}
                                />
                            ))}
                        </Box>
                        <Button
                            onClick={handleSubmit}
                            disabled={!dirty || !isEmpty(errors) || isFetching}
                            mb={24}
                            text={t('basics.login')}
                            isLoading={isFetching}
                            dataLocator="loginButton"
                        />

                        <ForgotPasswordLink
                            onClick={sendAnalyticResetPasswordClick}
                            to={PUBLIC.FORGOT_PASSWORD.path}
                            data-locator="ForgotPasswordButton"
                        >
                            <Text type="overline" center bold mb={20} text={t('basics.forgotPassword')} />
                        </ForgotPasswordLink>
                    </Box>
                </form>
            </S.AuthForm>
        </S.AuthContainer>
    );
};

export default Login;
