import { ERROR_LEVELS, SENTRY_AXIOS } from 'sentry-utils';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

import api from 'api';

import { selectCurrentUser } from 'store/user/selectors';
import { openModal } from 'store/modals/actions';

import sentry from 'services/Sentry/SentryInstance';
import {
    sendAnalyticAccountDeleteError,
    sendAnalyticAccountDeleteSuccess,
} from 'services/analytics/trackers/accountDelete';

import { useSelector } from 'hooks';

import { ModalName } from 'types/modals';

const useDeleteAccount = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const userData = useSelector(selectCurrentUser);
    const dispatch = useDispatch();

    const { user_id } = userData;

    const onDeleteAccount = async () => {
        if (!user_id) return;
        try {
            setIsLoading(true);
            await api.user.deleteAccount(user_id);
            setIsLoading(false);
            sendAnalyticAccountDeleteSuccess();
            dispatch(openModal(ModalName.DELETE_ACCOUNT_SUCCESS));
        } catch (error) {
            sendAnalyticAccountDeleteError();
            sentry.logError(new Error('Can not delete user'), SENTRY_AXIOS, ERROR_LEVELS.CRITICAL, {
                user_id,
            });
            dispatch(openModal(ModalName.DELETE_ACCOUNT_ERROR));
        }
    };

    return {
        onDeleteAccount,
        isLoading,
    };
};

export default useDeleteAccount;
