import { useCallback } from 'react';

import { selectSubscriptions } from 'store/subscriptions/selectors';
import { cancelSubscription, setCancellationCandidate } from 'store/subscriptions/actions';

import {
    sendAnalyticSubscriptionCancelError,
    sendAnalyticSubscriptionCancelRequest,
    sendAnalyticSubscriptionCancelSuccess,
} from 'services/analytics';

import { useDispatch, useSelector, useModals } from 'hooks';

import { ModalName } from 'types/modals';

const useCancelSubscription = () => {
    const dispatch = useDispatch();

    const { openModal, closeModal } = useModals();

    const { cancellationCandidate } = useSelector(selectSubscriptions);

    const discardCancellation = useCallback(() => {
        closeModal();

        dispatch(setCancellationCandidate(null));
    }, []);

    const onCancelSubscription = useCallback(() => {
        openModal(ModalName.WAITING_MODAL);
        sendAnalyticSubscriptionCancelRequest();
        if (cancellationCandidate) {
            dispatch(
                cancelSubscription({
                    externalId: cancellationCandidate.externalId,
                    paymentProvider: cancellationCandidate.paymentProvider,
                    onError: () => {
                        sendAnalyticSubscriptionCancelError();

                        discardCancellation();
                    },
                    onSuccess: () => {
                        sendAnalyticSubscriptionCancelSuccess();

                        discardCancellation();
                    },
                    onCancel: discardCancellation,
                })
            );
        }
    }, [cancellationCandidate]);

    return { discardCancellation, onCancelSubscription, cancellationCandidate };
};

export default useCancelSubscription;
