import { useTranslation } from 'react-i18next';

import { Box, Text } from 'components/library';

import * as S from './styled';

import { TabsProps } from './types';

const Tabs = ({ tabs, currentTab, onChange }: TabsProps) => {
    const { t } = useTranslation();

    return (
        <Box>
            {tabs.map((tab) => {
                const isActive = currentTab === tab.id;

                return (
                    <S.TabButton
                        $isActive={isActive}
                        onClick={() => onChange(tab.id)}
                        key={tab.id}
                        data-locator={tab.dataLocator}
                    >
                        <Text
                            type="overline"
                            color={isActive ? 'text-main' : 'text-secondary-subdued'}
                            text={t(tab.title)}
                        />
                    </S.TabButton>
                );
            })}
        </Box>
    );
};

export default Tabs;
