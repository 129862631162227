import { withAlpha } from './helpers';

import { Colors, Theme } from 'components/library';

export const color =
    (colorKey: Colors, alpha?: number) =>
    ({ theme }: { theme: Theme }) => {
        const themeColor = theme[colorKey];

        return alpha ? withAlpha(themeColor, alpha) : themeColor;
    };
