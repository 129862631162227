import { ActionEventAction } from 'wikr-core-analytics';

export enum linkType {
    Primary = 'primary',
    Secondary = 'secondary',
}

export interface SidebarLinkProps {
    to: string;
    title: string;
    dataLocator?: string;
    onClick?: (to?: string) => void;
    color?: string;
    type: linkType;
    target?: string;
    rel?: string;
    external?: boolean;
    isPurchasedAdditionalProduct?: boolean | null;
    analyticActionEvent?: ActionEventAction;
    customEventData?: string;
    badge?: string;
}

export interface SidebarLinkButtonProps {
    isActive: boolean;
    isPrimary: boolean;
    title: string;
    isPurchasedAdditionalProduct?: boolean | null;
    badge?: string;
}
