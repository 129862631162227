import styled, { keyframes } from 'styled-components';

import { color } from 'theme/selectors';

const animation = keyframes`
    100% {
        transform: translateX(100%);
    }
`;

export const SkeletonBlock = styled.span<{
    $width?: number;
    $height?: number;
    $mb?: number;
    $borderRadius?: string;
    $isBlockElement?: boolean;
}>`
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: ${({ $isBlockElement }) => ($isBlockElement ? 'block' : 'inline-flex')};
    width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
    height: ${({ $height }) => ($height ? `${$height}px` : '100%')};
    margin-bottom: ${({ $mb }) => ($mb ? `${$mb}px` : 0)};
    background-color: #e9e9ec;
    border-radius: ${({ $borderRadius }) => ($borderRadius ? `${$borderRadius}` : '8px')};

    &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: block;
        height: 100%;
        background: linear-gradient(90deg, #e9e9ec, ${color('surface-secondary')}, #e9e9ec), no-repeat;
        transform: translateX(-100%);
        animation: ${animation} 1.5s ease-in-out infinite;
    }
`;
