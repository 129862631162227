import { useNavigate } from 'react-router-dom';

import { PRIVATE, PUBLIC } from 'router/constants/routes';

import { isAuthenticatedSelector } from 'store/auth/selectors';

import { useSelector, useSidebarControl } from 'hooks';

import * as S from './styled';

import { ILogoProps } from './types';

const Logo = ({ height, isBigLogo }: ILogoProps) => {
    const isAuthenticated = useSelector(isAuthenticatedSelector);

    const { close } = useSidebarControl();
    const navigate = useNavigate();

    const logoLink = isAuthenticated ? PRIVATE.MAIN.path : PUBLIC.LOGIN.path;

    const handleLogoNavigate = () => {
        navigate(logoLink);

        close();
    };

    return (
        <S.LogoContainer onClick={handleLogoNavigate} $height={height} $isAuthenticated={isAuthenticated}>
            {isBigLogo ? <S.LogoImageBig /> : <S.LogoImage />}
        </S.LogoContainer>
    );
};

export default Logo;
