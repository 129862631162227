import React from 'react';

import { TITLE_WIDTH, TITLE_HEIGHT, GUIDE_HEIGHT, PURCHASED_AMOUNT, FREE_AMOUNT } from './constants';

import Skeleton from 'components/Skeleton';

const GuidesSkeleton = () => {
    const purchasedGuides = [];
    const freeGuides = [];

    for (let i = 0; i < PURCHASED_AMOUNT; i++) {
        purchasedGuides.push(<Skeleton key={i} height={GUIDE_HEIGHT} mb={12} />);
    }
    for (let i = 0; i < FREE_AMOUNT; i++) {
        freeGuides.push(<Skeleton key={i} height={GUIDE_HEIGHT} mb={12} />);
    }

    return (
        <>
            <Skeleton width={TITLE_WIDTH} height={TITLE_HEIGHT} />
            {purchasedGuides}
            <Skeleton width={TITLE_WIDTH} height={TITLE_HEIGHT} />
            {freeGuides}
        </>
    );
};

export default GuidesSkeleton;
