import workoutsBadge from './images/workouts.svg';
import weightBadge from './images/weight.svg';
import statsBadge from './images/stats.svg';
import reminderBadge from './images/reminder.svg';
import manBadge from './images/man.svg';
import integrationsBadge from './images/integrations.svg';
import habitBadge from './images/habit.svg';
import greenArrow from './images/greenArrov.svg';
import cardBadge from './images/card.svg';

type ReasonType = {
    title: string;
    badge: string;
    children: Array<{ title?: string; badge?: string; bodyText: string; type: 'card' | 'text' }>;
};
export const reminderReasons: Array<ReasonType> = [
    {
        title: 'subscription.reminderModal.reasons.fullAccess',
        badge: greenArrow,
        children: [
            {
                title: 'subscription.reminderModal.reasons.fullAccess.indoor.title',
                badge: manBadge,
                bodyText: 'subscription.reminderModal.reasons.fullAccess.indoor.bodyText',
                type: 'card',
            },
            {
                title: 'subscription.reminderModal.reasons.fullAccess.caloriesCounter.title',
                badge: weightBadge,
                bodyText: 'subscription.reminderModal.reasons.fullAccess.caloriesCounter.bodyText',
                type: 'card',
            },
            {
                title: 'subscription.reminderModal.reasons.fullAccess.workouts.title',
                badge: workoutsBadge,
                bodyText: 'subscription.reminderModal.reasons.fullAccess.workouts.bodyText',
                type: 'card',
            },
            {
                title: 'subscription.reminderModal.reasons.fullAccess.habits.title',
                badge: habitBadge,
                bodyText: 'subscription.reminderModal.reasons.fullAccess.habits.bodyText',
                type: 'card',
            },
            {
                title: 'subscription.reminderModal.reasons.fullAccess.integration.title',
                badge: integrationsBadge,
                bodyText: 'subscription.reminderModal.reasons.fullAccess.integration.bodyText',
                type: 'card',
            },
            {
                title: 'subscription.reminderModal.reasons.fullAccess.stats.title',
                badge: statsBadge,
                bodyText: 'subscription.reminderModal.reasons.fullAccess.stats.bodyText',
                type: 'card',
            },
        ],
    },
    {
        title: 'subscription.reminderModal.reasons.notify',
        badge: reminderBadge,
        children: [
            {
                bodyText: 'subscription.reminderModal.reasons.notify.bodyText',
                type: 'text',
            },
        ],
    },
    {
        title: 'subscription.reminderModal.reasons.charge',
        badge: cardBadge,
        children: [
            {
                bodyText: 'subscription.reminderModal.reasons.charge.bodyText',
                type: 'text',
            },
        ],
    },
];
