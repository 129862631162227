import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const Container = styled.div`
    max-width: 432px;
    margin: auto;
    padding: 16px;
`;

export const RadioChecker = styled.div<{ $isSelected: boolean }>`
    min-width: 24px;
    min-height: 24px;
    border: ${({ $isSelected, theme }) =>
        $isSelected ? `4px solid  ${theme['surface-default']}` : `2px solid ${theme['border-default']}`};
    border-radius: 50%;

    margin-right: 12px;
`;
export const ReasonItem = styled.button<{ $isSelected: boolean }>`
    background: ${color('surface-default')};
    padding: 28px 20px;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    text-align: left;
    margin-bottom: 12px;
    cursor: pointer;
    min-height: 80px;
    overflow: hidden;
    transition: 0.2s ease;

    > * {
        transition: 0.2s ease;
    }

    ${({ $isSelected }) =>
        $isSelected &&
        css`
            background: ${color('primary-default')};

            > * {
                color: ${color('on-primary')};
            }
        `}
    ${mediaQueries.laptop} {
        &:hover {
            background: ${color('primary-default')};

            > * {
                color: ${color('on-primary')};
            }
        }
    }
`;
