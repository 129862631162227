import styled from 'styled-components';

import { color } from 'theme/selectors';

export const Container = styled.div`
    position: relative;
    margin-left: 28px;
    padding-left: 40px;
    border-left: 1px solid ${color('border-default')};
    padding-bottom: 40px;
`;

export const Badge = styled.div`
    position: absolute;
    top: -12px;
    left: -28px;
`;
