import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const PeriodItem = styled.button<{ $isSelected: boolean }>`
    background: ${color('surface-default')};
    padding: 16px 20px;
    display: block;
    width: 100%;
    border-radius: 12px;
    text-align: left;
    margin-bottom: 12px;
    cursor: pointer;
    height: 80px;
    overflow: hidden;
    transition: 0.2s ease;

    > * {
        transition: 0.2s ease;
    }

    ${({ $isSelected }) =>
        $isSelected &&
        css`
            background: ${color('primary-default')};

            > * {
                color: ${color('on-primary')};
            }
        `}
    ${mediaQueries.laptop} {
        &:hover {
            background: ${color('primary-default')};

            > * {
                color: ${color('on-primary')};
            }
        }
    }
`;
