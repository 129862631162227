import styled from 'styled-components';

export const Link = styled.a`
    display: block;
    margin: 0 auto;
    width: fit-content;

    & > p {
        display: inline;
    }
`;
