import { UserStore } from 'types/store/userStore';

import { createSelector } from 'reselect';
import { RootReducer } from '../rootReducer';

const selectCurrentUser = (state: RootReducer): UserStore => state.user;

const selectCountry = createSelector(selectCurrentUser, (user: UserStore) => user.country);
const selectGender = createSelector(selectCurrentUser, (user: UserStore) => user.gender);
const selectFitnessLevel = createSelector(selectCurrentUser, (user: UserStore) => user.fitness_level);
const selectIsCloseInformationModal = createSelector(
    selectCurrentUser,
    (user: UserStore) => user.isCloseInformationModal
);

export { selectCurrentUser, selectCountry, selectGender, selectIsCloseInformationModal, selectFitnessLevel };
