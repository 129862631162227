import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import React, { createElement, ElementType } from 'react';

import RootLayout from 'router/layouts/RootLayout';
import CabinetLayout from 'router/layouts/CabinetLayout';
import AuthLayout from 'router/layouts/AuthLayout';
import { PUBLIC, PRIVATE } from 'router/constants/routes';

import { RoutesType, LayoutProps } from './types';

import RootBoundary from './RootBoundary';

const createRoutes = (routes: RoutesType[], layout: ElementType<LayoutProps>) =>
    routes.map(({ path, titleKey, component: Component }: RoutesType) => (
        <Route key={path} path={path} element={createElement(layout, { titleKey }, <Component />)} />
    ));

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<RootLayout />} errorElement={<RootBoundary />}>
            {createRoutes(Object.values(PUBLIC), AuthLayout)}
            {createRoutes(Object.values(PRIVATE), CabinetLayout)}
        </Route>
    )
);

export default router;
