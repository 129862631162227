import {
    IDeepLinkData,
    ISignInData,
    IUserInfoResponse,
    IUserUpdateData,
    IChangePasswordType,
    IHashedEmailResp,
} from 'types/user/userApiInterface';
import { IGuidesResp } from 'types/guide';
import { ICourseLinkResponse } from 'types/courses';

import Base from './Base';

class UserApi extends Base {
    getUser = async <Response = IUserInfoResponse>(): Promise<Partial<Response>> => {
        return await this.apiClient.private.get<Response>('user/');
    };

    update = async <Data = IUserUpdateData, Response = IUserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response>> => {
        return await this.apiClient.private.patch<Data, Response>('user/', payload);
    };

    changePassword = async <Data = IChangePasswordType, Response = IUserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response>> => {
        return await this.apiClient.private.post<Data, Response>('user/password-change', payload);
    };

    signIn = async <Data = ISignInData, Response = IUserInfoResponse>(payload: Data): Promise<Partial<Response>> => {
        return await this.apiClient.public.post<Data, Response>('sign-in/', payload);
    };

    resetPassword = async <Data = ISignInData, Response = IUserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response>> => {
        return await this.apiClient.public.post<Data, Response>('user/reset-password', payload);
    };

    deepLinks = async <Response = IDeepLinkData>(): Promise<Partial<Response>> => {
        return await this.apiClient.private.get<Response>('user/auth-deeplink');
    };

    getUserBySignature = async <Response = IUserInfoResponse>(
        params: Record<string, string>
    ): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.public.get<Response>(`core/user/${params.uId}/${params.signature}`);
    };

    hashedEmail = async () => {
        return await this.apiClient.private.get<IHashedEmailResp>('front-hash');
    };

    getProducts = async () => {
        return await this.apiClient.private.get<IGuidesResp>('user/products');
    };

    courseLink = async () => {
        return await this.apiClient.private.get<ICourseLinkResponse>('user/auth-course-link');
    };

    deleteAccount = async (userId: number) => {
        return await this.apiClient.private.delete<ICourseLinkResponse>(`users/${userId}`);
    };
}

export default UserApi;
