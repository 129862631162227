import { PRODUCT_CODES } from 'constants/payments';

import { ISubscription } from 'types/subscription';

const MOVE_LEFT = -1;
const MOVE_RIGHT = 1;
const STAY = 0;

export const sortFullAccessFirst = (subscriptions: Array<ISubscription>): Array<ISubscription> =>
    subscriptions.sort((a, b) => {
        if (a.product_code === PRODUCT_CODES.APP_FULL_ACCESS) return MOVE_LEFT;
        if (b.product_code === PRODUCT_CODES.APP_FULL_ACCESS) return MOVE_RIGHT;

        return STAY;
    });
