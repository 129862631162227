import { DeviceType, StringDictionary } from 'wikr-core-analytics/dist/types';
import { UserId } from 'wikr-core-analytics/dist/Interfaces/DataProvider';
import { Main, uaParser } from 'wikr-core-analytics';
import { SENTRY_AXIOS } from 'sentry-utils';
import { Event } from '@sentry/react';

import { objectEntriesPolyfill } from 'helpers/polyfills';

import { Extras, ErrorType, Tags, IError } from './types';

const IOS = 'iOS';
const ANDROID = 'Android';
const CHROME = 'Chrome';
const IOS_MIN_VERSION = 14.3;
const ANDROID_MIN_VERSION = 7;
const CHROME_MIN_VERSION = 59;

const WIDTH_SIZE = 2000;
const HEIGHT_SIZE = 2000;

export const checkFbBots = () => {
    const windowWidth = window.screen.width;
    const windowHeight = window.screen.height;

    return windowWidth === WIDTH_SIZE && windowHeight === HEIGHT_SIZE;
};

export const getCustomTags = (error: IError | unknown, type: ErrorType, extras?: Extras): Tags => {
    const customTags: Record<string, string> = {};

    if (type === SENTRY_AXIOS) {
        customTags.API_ERROR_CODE = extras?.status as string;
    }

    return objectEntriesPolyfill(customTags);
};

export const checkIsOldDevices = (event: Event) => {
    const UAParser = new uaParser();
    const getUserAgent = typeof event?.extra?.agent === 'string' ? event?.extra?.agent : '';
    const userAgent = UAParser.setUA(getUserAgent);

    const browser = userAgent && userAgent.getBrowser();
    const browserMajor = browser && browser.major;
    const browserName = browser && browser.name;

    const deviceOS = userAgent && userAgent.getOS();
    const deviceOSVersion = deviceOS && deviceOS.version;
    const deviceOSName = deviceOS && deviceOS.name;

    const parsedOSVersion = parseFloat(deviceOSVersion || '');
    const parsedBrowserVersion = parseFloat(browserMajor || '');

    return (
        (deviceOSName === IOS && parsedOSVersion < IOS_MIN_VERSION) ||
        (deviceOSName === ANDROID && parsedOSVersion < ANDROID_MIN_VERSION) ||
        (browserName === CHROME && parsedBrowserVersion < CHROME_MIN_VERSION)
    );
};

export const getAmazonDefaultData = async (): Promise<{
    aws_id: string;
    event_type: string;
    host: string;
    url: string;
    screen_height: number;
    screen_width: number;
    lang: string;
    language: string;
    agent: string;
    device: string | undefined;
    referrer: string;
    timestamp: number;
    browser_info: object;
    device_type: DeviceType;
    uuid: string;
    user_id: UserId;
    url_params: StringDictionary;
    country: string | null;
    cookies: object;
    gender: string;
    ab_test_name: string;
    growthbook_ab_test_name: string;
    region: string | null;
} | null> => {
    const isInitAmazonAnalytic = Main.checkIsAlreadyInited({ name: 'amazon' });

    if (isInitAmazonAnalytic) {
        return await Main?.getDefaultAmazonAnalyticPurchaseData?.();
    }

    return null;
};
