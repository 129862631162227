import { Main, systemNames } from 'wikr-core-analytics';

import config from 'config';

export const initAnalytics = () => {
    Main.initSystems({
        activeSystems: [
            {
                name: systemNames.amazon,
                id: config.AMAZON_ID,
                config: {
                    releaseDate: config.RELEASE_DATE,
                    streamName: config.AWS_DELIVERY_STREAM_NAME,
                    envMode: config.ENV,
                },
            },
        ],
    });
};
