import { initReactI18next } from 'react-i18next';
import { useEffect, useState } from 'react';
import backend from 'i18next-xhr-backend';
import ICU from 'i18next-icu';
import i18next from 'i18next';

import { getLanguage, setLanguage } from 'api/utils/languageManagment';

import { DEFAULT_LANGUAGE } from 'constants/localization';

import { getLocalizationFromAvailable, getCurrentLocalization } from 'helpers/localization';

const languageFromStorage = getLanguage();
const navigatorLanguage = getLocalizationFromAvailable();
const languageFromURL = getCurrentLocalization();

export const resetLanguage = (incomingLanguage) => {
    const language =
        navigatorLanguage || languageFromURL || incomingLanguage || languageFromStorage || DEFAULT_LANGUAGE;

    setLanguage(language);

    if (i18next.isInitialized && i18next.language !== language) {
        i18next.changeLanguage(language);
    }
};

resetLanguage(); // set language on app start

const useI18next = () => {
    const [isTranslationsLoaded, setLoadingState] = useState(false);

    useEffect(() => {
        i18next
            .use(backend)
            .use(ICU)
            .use(initReactI18next)
            .init(
                {
                    lng: navigatorLanguage || languageFromURL || languageFromStorage,
                    fallbackLng: [DEFAULT_LANGUAGE],
                    ns: ['translation'],
                    preload: [DEFAULT_LANGUAGE],
                    debug: false,
                    react: {
                        useSuspense: false,
                    },
                    nsSeparator: false,
                    keySeparator: false,
                },
                () => {
                    setLoadingState(true);
                }
            );
    }, []);

    return {
        isTranslationsLoaded,
    };
};

export default useI18next;
