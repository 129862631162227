import { withTheme } from 'styled-components';
import React from 'react';

import { Theme, Colors } from 'components/library';

const CrossCircle = ({ theme, color }: { theme: Theme; color?: Colors }) => {
    const fill = theme[color || 'success-default'];

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="M17.4999 9.99995C17.4999 14.1421 14.1421 17.4999 9.99995 17.4999C5.85779 17.4999 2.5 14.1421 2.5 9.99995C2.5 5.85779 5.85779 2.5 9.99995 2.5C14.1421 2.5 17.4999 5.85779 17.4999 9.99995"
                    stroke={fill}
                    strokeWidth="1.59999"
                    strokeMiterlimit="10"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.465 7.49131C12.2168 7.24313 11.8144 7.24313 11.5663 7.49131L10.1811 8.87648L8.79591 7.49131C8.54774 7.24313 8.14536 7.24313 7.89718 7.49131L7.6725 7.71599C7.42432 7.96417 7.42432 8.36654 7.6725 8.61472L9.05767 9.99989L7.67246 11.3851C7.42428 11.6333 7.42428 12.0357 7.67246 12.2838L7.89714 12.5085C8.14532 12.7567 8.5477 12.7567 8.79587 12.5085L10.1811 11.1233L11.5663 12.5085C11.8145 12.7567 12.2168 12.7567 12.465 12.5085L12.6897 12.2838C12.9379 12.0357 12.9379 11.6333 12.6897 11.3851L11.3045 9.99989L12.6897 8.61472C12.9378 8.36654 12.9378 7.96417 12.6897 7.71599L12.465 7.49131Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};

export default withTheme(CrossCircle);
