import * as actionTypes from './actionTypes';

import { CancelCandidate, ISubscription } from 'types/subscription';
import { ICancelSubscription } from './types';

export const setCancellationCandidate = (payload: CancelCandidate | null) => {
    return {
        type: actionTypes.SET_CANCELLATION_CANDIDATE,
        payload,
    } as const;
};

export const fetchSubscriptions = () => {
    return {
        type: actionTypes.FETCH_USER_SUBSCRIPTIONS,
    } as const;
};

export const setSubscriptions = (payload: Array<ISubscription>) => {
    return {
        type: actionTypes.SET_USER_SUBSCRIPTIONS,
        payload,
    } as const;
};

export const cancelSubscription = (payload: ICancelSubscription) => {
    return {
        type: actionTypes.CANCEL_SUBSCRIPTION,
        payload,
    } as const;
};

export const discardSubscriptionCancellation = () => {
    return {
        type: actionTypes.DISCARD_SUBSCRIPTION_CANCELLATION,
    } as const;
};

export const setSubscriptionsFetchingStatus = (payload: boolean) => {
    return {
        type: actionTypes.SET_FETCHING_STATUS,
        payload,
    } as const;
};

export const setSubscriptionCancelReminder = (payload: ICancelSubscription) => {
    return {
        type: actionTypes.SET_CANCEL_REMINDER,
        payload,
    } as const;
};
