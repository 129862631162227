import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
`;

export const Icon = styled.img`
    margin-top: -28px;
    width: 327px;
`;
