import { useTranslation } from 'react-i18next';
import React from 'react';

import WaitingModal from 'modals/WaitingModal';

const WaitingModalContainer = () => {
    const { t } = useTranslation();

    return (
        <WaitingModal
            captions={{
                title: t('subscription.cancelDialog.loading'),
            }}
            dataLocator="cancellProgressModal"
        />
    );
};

export default WaitingModalContainer;
