import { useTranslation } from 'react-i18next';

import { Text } from 'components/library';

import * as S from './styled';

type Props = {
    title: string;
    badge: string;
    bodyText: string;
};

const ReasonCard = ({ title, badge, bodyText }: Props) => {
    const { t } = useTranslation();

    return (
        <S.Container>
            <S.Header>
                <img width={24} src={badge} alt={t(title)} />
                <Text type="large-text" text={t(title)} />
            </S.Header>

            <Text type="small-text" text={t(bodyText)} />
        </S.Container>
    );
};

export default ReasonCard;
