import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import { delay, put, take, takeLatest, fork, cancel, cancelled, all, call } from 'redux-saga/effects';
import { Task } from 'redux-saga';
import { CancelledEffect } from '@redux-saga/core/effects';

import { ISubscriptionCancelResponse } from 'api/types/response';
import api from 'api';

import {
    setSubscriptions,
    setSubscriptionsFetchingStatus,
    cancelSubscription,
    fetchSubscriptions,
    setSubscriptionCancelReminder,
} from './actions';
import { notifyError, notifySuccess, notify } from '../notifications/actions';

import {
    CANCEL_SUBSCRIPTION,
    DISCARD_SUBSCRIPTION_CANCELLATION,
    FETCH_USER_SUBSCRIPTIONS,
    SET_CANCEL_REMINDER,
} from './actionTypes';

import sentry from 'services/Sentry/SentryInstance';

import { sortFullAccessFirst } from './helpers';

import { ISubscription } from 'types/subscription';
import { ICancelSubscription } from './types';

function* getSubscription() {
    try {
        const response: ISubscription[] = yield call(api.subscriptions.getSubscriptions);

        yield put(setSubscriptions(sortFullAccessFirst(response)));
        yield put(setSubscriptionsFetchingStatus(false));
    } catch (error) {
        notifyError('getSubscription error');
    }
}

function* makeSubscriptionCancelling({ payload }: ReturnType<typeof cancelSubscription>) {
    try {
        const workerTask: Task = yield fork(callUnsubscribe, payload);

        yield take(DISCARD_SUBSCRIPTION_CANCELLATION);
        yield cancel(workerTask);
    } catch (error) {
        payload.onError();
    }
}

function* callUnsubscribe(payload: ICancelSubscription) {
    try {
        yield delay(3000);

        const response: ISubscriptionCancelResponse = yield call(api.subscriptions.unsubscribe, {
            external_id: payload.externalId,
            payment_provider: payload.paymentProvider,
        });

        if (!response.result) {
            throw new Error('Subscription is not cancelled');
        }

        yield put(fetchSubscriptions());
        yield put(notifySuccess('subscription.cancellation.response.success'));

        payload.onSuccess();
    } catch (error: any) {
        yield put(notifyError('subscription.cancellation.response.error'));

        sentry.logError(`subscription_cancellation_error | ${error?.message}`, SENTRY_APP, ERROR_LEVELS.CRITICAL, {
            reason: 'subscription_cancellation_response_error',
            details: error,
            externalId: payload?.externalId,
            payload,
        });

        payload.onError();
    } finally {
        const isCancelled: CancelledEffect = yield cancelled();

        if (isCancelled) {
            yield put(notify('subscription.cancellation.response.abort'));

            payload.onCancel();
        }
    }
}

function* setReminder({ payload }: ReturnType<typeof setSubscriptionCancelReminder>) {
    try {
        const response: string = yield call(api.subscriptions.setReminder, {
            external_id: payload.externalId,
        });

        if (response !== 'success') {
            throw new Error('Subscription reminder is not set');
        }

        payload.onSuccess();
    } catch (error: any) {
        console.error('subscription_set_reminder_error with external_id', payload.externalId);
        sentry.logError(`subscription_set_reminder_error | ${error?.message}`, SENTRY_APP, ERROR_LEVELS.CRITICAL, {
            reason: 'subscription_cancellation_response_error',
            details: error,
            externalId: payload?.externalId,
            payload,
        });
        payload.onError();
    } finally {
        const isCancelled: CancelledEffect = yield cancelled();

        if (isCancelled) {
            yield put(notify('subscription.cancellation.response.abort'));

            payload.onCancel();
        }
    }
}

export default function* watchSubscriptions() {
    yield all([
        takeLatest(FETCH_USER_SUBSCRIPTIONS, getSubscription),
        takeLatest(CANCEL_SUBSCRIPTION, makeSubscriptionCancelling),
        takeLatest(SET_CANCEL_REMINDER, setReminder),
    ]);
}
