export const DEVICE_OS = {
    ANDROID: 'ANDROID',
    IOS: 'IOS',
    MAC_OS: 'MAC OS',
    DEFAULT: 'DEFAULT_DEVICE',
};

export const STORE_LINKS = {
    ios: 'https://apps.apple.com/app/id1457956232',
    android: 'https://play.google.com/store/apps/details?id=com.walkfit.weightloss.steptracker.pedometer',
};

export const COURSE_LINK = 'wellcube.pro';
