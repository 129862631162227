import { iUiStore } from 'types/ui';

import { createSelector } from 'reselect';
import { RootReducer } from '../rootReducer';

const selectUi = (state: RootReducer) => state.ui;

const selectIsOpenSidebar = createSelector(selectUi, (ui: iUiStore) => ui.isOpenSidebar);

export { selectIsOpenSidebar };
