import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { PRIVATE } from 'router/constants/routes';

import { selectCurrentUser } from 'store/user/selectors';
import { closeModal } from 'store/modals/actions';

import { accountTabs, TABS } from 'constants/account';

import { useDispatch, useSelector } from 'hooks/store';

import { Text } from 'components/library';
import Tabs from './components/Tabs';
import SubscriptionsTab from './components/SubscriptionsTab';
import SettingsTab from './components/SettingsTab';
import ProfileTab from './components/ProfileTab';

import * as S from './styled';

import defaultUserPic from 'assets/img/default-avatar.svg';

import { UserStore } from 'types/store/userStore';

const AccountSettings = () => {
    const navigate = useNavigate();
    const params: { tabId?: string } = useParams();
    const dispatch = useDispatch();

    const currentUser: UserStore = useSelector(selectCurrentUser);

    const tabId = params.tabId;
    const currentTabId = tabId || accountTabs.Profile;

    const handleChangeTab = (tabId: string) => {
        const path = `${PRIVATE.ACCOUNT_DEFAULT.path}/${tabId}`;

        navigate(path);
    };

    useEffect(() => {
        // this action need for the case when user click "back" btn in browser
        dispatch(closeModal());
    }, [currentTabId]);

    const getCurrentTab = (currentTab: string) => {
        switch (currentTab) {
            case accountTabs.Subscription:
                return <SubscriptionsTab />;

            case accountTabs.Settings:
                return <SettingsTab />;

            default:
                return <ProfileTab />;
        }
    };

    return (
        <>
            <S.Container>
                <S.AvatarWrapper paddingTop={24} paddingBottom={16} paddingX={16}>
                    <S.Image alt="avatar" src={defaultUserPic} />
                    <Text type="h5" text={`${currentUser?.name ?? ''} ${currentUser?.last_name ?? ''}`} />
                </S.AvatarWrapper>
                <S.ContentWrapper>
                    <Tabs tabs={TABS} currentTab={currentTabId} onChange={handleChangeTab} />
                </S.ContentWrapper>
            </S.Container>
            <S.ContentWrapper paddingX={16}>{getCurrentTab(currentTabId)}</S.ContentWrapper>
        </>
    );
};

export default AccountSettings;
