import appConfig from 'appConfig';

import { getLanguage } from 'api/utils/languageManagment';

import { DEFAULT_LANGUAGE } from 'constants/localization';

import isMobile from './isMobile';

type KeyType = keyof typeof appConfig.faqLinks.ios;

export const getFaqLink = () => {
    const language = getLanguage();

    const { faqLinks } = appConfig;

    if (isMobile.iOS()) {
        return faqLinks.ios[language as KeyType] || faqLinks.ios[DEFAULT_LANGUAGE];
    }

    if (isMobile.android()) {
        return faqLinks.android[language as KeyType] || faqLinks.android[DEFAULT_LANGUAGE];
    }

    return faqLinks.desktop[language as KeyType] || faqLinks.desktop[DEFAULT_LANGUAGE];
};
