import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

import { Text } from 'components/library';
import RoundButton from 'components/BackButton/BackButton';

import * as S from './styled';

type Props = {
    title: string;
    badge: string;
    children: ReactNode;
};

const badgeImage = (badge: string) => () => {
    return <img width={28} src={badge} alt="badge" />;
};

const ReminderReason = ({ title, children, badge }: Props) => {
    const { t } = useTranslation();

    return (
        <S.Container>
            <S.Badge>
                <RoundButton size={56} badge={badgeImage(badge)} backgroundColor="surface-secondary" />
            </S.Badge>
            <Text mb={20} type="h6" text={t(title)} />
            {children}
        </S.Container>
    );
};

export default ReminderReason;
