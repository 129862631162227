import { Outlet } from 'react-router-dom';

import ErrorModalContainer from 'api/components/ErrorModalContainer';

import { isAuthenticationCompleteSelector } from 'store/auth/selectors';

import useI18next from 'services/i18n';
import { useAnalytic } from 'services/analytics/useAnalytic';

import { useAuthentication, useSelector } from 'hooks';

import ModalsContainer from 'containers/modals/ModalsContainer';

import PageLoader from 'components/PageLoader';

const RootLayout = () => {
    const isComplete = useSelector(isAuthenticationCompleteSelector);

    const { isTranslationsLoaded } = useI18next();

    useAuthentication();
    useAnalytic();

    const isReady = isTranslationsLoaded && isComplete;

    return isReady ? (
        <>
            <ErrorModalContainer />
            <Outlet />
            <ModalsContainer />
        </>
    ) : (
        <PageLoader />
    );
};

export default RootLayout;
