export default {
    ios: {
        en: 'https://support.walkfit.pro/en/categories/362946-ios',
        fr: 'https://support.walkfit.pro/fr/categories/362946-ios',
        de: 'https://support.walkfit.pro/de/categories/362946-ios',
        it: 'https://support.walkfit.pro/it/categories/362946-ios',
        es: 'https://support.walkfit.pro/es/categories/362946-ios',
        pt: 'https://support.walkfit.pro/pt-PT/categories/362946-ios',
        uk: 'https://support.walkfit.pro/uk/categories/362946-ios',
    },
    android: {
        en: 'https://support.walkfit.pro/en/categories/363394-android',
        fr: 'https://support.walkfit.pro/fr/categories/363394-android',
        de: 'https://support.walkfit.pro/de/categories/363394-android',
        it: 'https://support.walkfit.pro/it/categories/363394-android',
        es: 'https://support.walkfit.pro/es/categories/363394-android',
        pt: 'https://support.walkfit.pro/pt-PT/categories/363394-android',
        uk: 'https://support.walkfit.pro/uk/categories/363394-android',
    },
    desktop: {
        en: 'https://support.walkfit.pro/en',
        fr: 'https://support.walkfit.pro/fr',
        de: 'https://support.walkfit.pro/de',
        it: 'https://support.walkfit.pro/it',
        es: 'https://support.walkfit.pro/es',
        pt: 'https://support.walkfit.pro/pt-PT',
        uk: 'https://support.walkfit.pro/uk',
    },
};
