import { Colors } from 'wikr-core-components';
import styled from 'styled-components';

// theme
import { color } from 'theme/selectors';

export const RoundedButton = styled.button<{
    $withShadow?: boolean;
    $size: number;
    $backgroundColor: Colors;
}>`
    background-color: ${({ $backgroundColor }) => color($backgroundColor)};
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ $size }) => `${$size}px`};

    width: ${({ $size }) => `${$size}px`};
    padding: 0;
    margin: 0;
    ${(props) =>
        props.$withShadow &&
        `
        box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    `}
`;
