import { Trans, useTranslation } from 'react-i18next';
import React from 'react';

import Modal from 'components/Modal';
import { Box, Button, Text } from 'components/library';
import ContactUsLink from 'components/ContactUsLink/ContactUsLink';

import * as S from './styled';

interface Props {
    isOpen: boolean;
    onClose?: () => void;
    tryAgain?: () => void;
    description: React.ReactNode;
    icon: string;
}

const ErrorMessageModal = ({ isOpen, onClose, description, icon, tryAgain }: Props) => {
    const { t } = useTranslation();

    const onTryAgain = () => {
        return tryAgain ? tryAgain() : window.location.reload();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
            <S.Wrapper data-locator="cancelSubModal">
                <S.Icon src={icon} alt="modalIcon" />
                <Text type="h5" center mb={12}>
                    <Trans i18nKey="guides.error.sorry" />
                </Text>
                <Text center mb={32} color="text-secondary">
                    {description}
                </Text>
                <Button dataLocator="ErrorMessageModal" onClick={onTryAgain}>
                    <Trans i18nKey="basics.tryAgain" />
                </Button>
                <Box paddingTop={25} paddingBottom={25}>
                    <ContactUsLink text={t('basics.contactSupport')} />
                </Box>
            </S.Wrapper>
        </Modal>
    );
};

export default ErrorMessageModal;
