import { useTranslation } from 'react-i18next';
import { MouseEventHandler } from 'react';

import { Text } from 'components/library';
import { ArrowLeft, Cross } from 'components/Icon';
import RoundButton from 'components/BackButton';

import * as S from './styled';

type Props = {
    onClose: MouseEventHandler<HTMLButtonElement> & (() => void);
    onBack?: MouseEventHandler<HTMLButtonElement> & (() => void);
};
const ModalHeader = ({ onClose, onBack }: Props) => {
    const { t } = useTranslation();

    return (
        <S.Container>
            {onBack && (
                <S.ReturnBack>
                    <RoundButton
                        onClick={onBack}
                        badge={ArrowLeft}
                        badgeColor="text-main"
                        backgroundColor="surface-secondary"
                    />
                </S.ReturnBack>
            )}
            <Text center type="large-text" text={t('subscription.text.cancel')} />
            <S.CloseButton>
                <RoundButton
                    onClick={onClose}
                    badge={Cross}
                    badgeColor="text-main"
                    backgroundColor="surface-secondary"
                />
            </S.CloseButton>
        </S.Container>
    );
};

export default ModalHeader;
