import { useTranslation } from 'react-i18next';
import React, { ChangeEvent } from 'react';
import { FormikProps } from 'formik';

import { measure } from 'constants/units';

import { radioFields } from '../utils/helpers';

import { isEmpty } from 'helpers/dataStructuresUtils';

import Radio from 'components/Radio';
import { Box, Button, Text } from 'components/library';
import FormikInput from 'components/FormikInput';

import * as S from '../styled';

import { UnitTypes } from 'types/user/userApiInterface';
import { heightInput, nameInput, formValues } from '../types';

interface IProfileForm {
    currentUnit: UnitTypes;
    nameFields: nameInput[];
    heightFields: heightInput[];
    setUnit: (event: ChangeEvent) => void;
    isLoading?: boolean;
}

const { MEASURE_UNITS, WEIGHT_UNIT, TALL_UNIT, MEASURE_DATA_LOCATORS } = measure;

function ProfileForm({
    nameFields,
    setFieldTouched,
    values,
    setFieldValue,
    touched,
    errors,
    handleChange,
    currentUnit,
    heightFields,
    handleSubmit,
    setUnit,
    isLoading,
    dirty,
}: IProfileForm & FormikProps<formValues>) {
    const { t } = useTranslation();

    const measureUnits = Object.values(MEASURE_UNITS);

    const genderToggleHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setFieldTouched('gender');
        handleChange(e);
    };

    const isTouched = Boolean(dirty || Object.keys(touched).length);

    return (
        <Box paddingTop={40}>
            {nameFields.map(({ name, label, dataLocator, placeholder }) => (
                <S.InputWrapper key={name}>
                    <FormikInput
                        mb={34}
                        field={name}
                        label={label}
                        type="text"
                        setFieldTouched={setFieldTouched}
                        initialValues={values}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                        errors={errors}
                        dataLocator={dataLocator}
                        placeholder={placeholder}
                    />
                </S.InputWrapper>
            ))}

            <Box paddingX={12}>
                <Text
                    text={t('account.profile.gender.title')}
                    type="caption"
                    color="text-secondary-subdued"
                    bold
                    mb={16}
                />
            </Box>
            <S.RadioContainer mb={36} paddingX={14}>
                {/* Todo: use RadioButton from libComponents then issue will be resolved*/}
                {radioFields.map((radio) => (
                    <Radio
                        checked={values.gender === radio.name}
                        onChange={genderToggleHandler}
                        name="gender"
                        key={radio.name}
                        label={radio.label}
                        value={radio.name}
                        dataLocator={radio.dataLocator}
                    />
                ))}
            </S.RadioContainer>
            <S.InputWrapper>
                <FormikInput
                    field="age"
                    mb={34}
                    label={t('account.profile.age')}
                    setFieldTouched={setFieldTouched}
                    initialValues={values}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    errors={errors}
                    type="number"
                    dataLocator="ageInput"
                    placeholder={t('account.profile.age')}
                />
            </S.InputWrapper>
            <Box mb={36}>
                <Box paddingX={12} mb={15}>
                    <Text text={t('account.profile.button.units')} type="caption" color="text-secondary-subdued" bold />
                </Box>
                <Box paddingX={10}>
                    <S.Switcher
                        defaultUnit={MEASURE_UNITS[currentUnit]}
                        data={measureUnits}
                        onChange={setUnit}
                        translatedData={measureUnits}
                        dataLocators={MEASURE_DATA_LOCATORS}
                    />
                </Box>
            </Box>
            <S.InputWrapper>
                <FormikInput
                    field="weight"
                    mb={34}
                    label={t('account.profile.weight')}
                    setFieldTouched={setFieldTouched}
                    initialValues={values}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    errors={errors}
                    unit={WEIGHT_UNIT[currentUnit]}
                    type="number"
                    dataLocator="currentWeightInput"
                    placeholder={t('account.profile.weight')}
                />
            </S.InputWrapper>
            {currentUnit === UnitTypes.Metric && (
                <S.InputWrapper>
                    <FormikInput
                        field="heightMetric"
                        label={t('account.profile.height')}
                        setFieldTouched={setFieldTouched}
                        initialValues={values}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                        errors={errors}
                        type="number"
                        mb={46}
                        unit={TALL_UNIT.metric}
                        dataLocator="heightInput"
                        placeholder={t('account.profile.height')}
                    />
                </S.InputWrapper>
            )}
            {currentUnit === UnitTypes.Imperial && (
                <S.ShortInputsWrapper>
                    {heightFields.map((input: heightInput) => (
                        <S.InputWrapper key={input.field}>
                            <FormikInput
                                setFieldTouched={setFieldTouched}
                                initialValues={values}
                                setFieldValue={setFieldValue}
                                touched={touched}
                                values={values}
                                errors={errors}
                                type="number"
                                mb={26}
                                dataLocator={input.dataLocator}
                                {...input}
                            />
                        </S.InputWrapper>
                    ))}
                </S.ShortInputsWrapper>
            )}
            <Button
                onClick={handleSubmit}
                isLoading={isLoading}
                backgroundColor="primary-default"
                disabled={!isTouched || !isEmpty(errors)}
                mb={56}
                text={t('account.profile.button.save')}
                dataLocator="saveProfileChangesBtn"
            />
        </Box>
    );
}

export default ProfileForm;
