import { useTranslation } from 'react-i18next';
import React from 'react';

import { useModals } from 'hooks';

import ErrorMessageModal from 'modals/ErrorMessageModal';

import exclamationImg from './img/exclamation.svg';

const ErrorModalContainer = ({ tryAgain }: { tryAgain?: () => void }) => {
    const { t } = useTranslation();
    const { closeModal } = useModals();

    return (
        <ErrorMessageModal
            isOpen
            onClose={closeModal}
            description={t('errorModal.description')}
            icon={exclamationImg}
            tryAgain={tryAgain}
        />
    );
};

export default ErrorModalContainer;
