import { ActionEventAction, ElementEventAction } from 'wikr-core-analytics';

import { trackClick } from './mainTrackers';

export const sendAnalyticSideBarElementClick = ({
    actionEvent,
    customEventData,
}: {
    actionEvent?: ActionEventAction;
    customEventData?: string;
}) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent,
        customEventData,
    });
};

export const sendAnalyticSideBarLogoutClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.logOut,
    });
};
