import { useTranslation, Trans } from 'react-i18next';
import React, { useState, useRef } from 'react';

import { reasons } from './constants';

import { sendAnalyticSubscriptionKeepClick } from 'services/analytics';

import OutlinedButton from 'components/OutlinedButton';
import ModalHeader from 'components/ModalHeader';
import Modal from 'components/Modal';
import { Text, Button, Box } from 'components/library';

import * as S from './styled';

import { ICancellationReasonModal } from 'types/modals';

const CancellationReasonModal = ({ onSubmit, onDiscard, onClose, isFreezeSubscription }: ICancellationReasonModal) => {
    const { t } = useTranslation();
    const cancellationReasonBtn = useRef<null | HTMLDivElement>(null);

    const [reason, setReason] = useState<string | null>(null);

    const handleSelectReason = (shortKey: string) => () => {
        setReason(shortKey);

        cancellationReasonBtn.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    };

    const handleDiscard = () => {
        setReason(null);
        sendAnalyticSubscriptionKeepClick();

        onDiscard();
    };

    const titleKey = 'subscription.cancellationReason.why';

    return (
        <Modal isOpen fullscreen customStyles={{ content: { overflow: 'scroll' } }}>
            <ModalHeader onClose={onClose} />
            <S.Container>
                <Box paddingTop={20} paddingBottom={100}>
                    <Text type="h4" center mb={24}>
                        <Trans i18nKey={titleKey} components={{ 1: <span style={{ color: '#1975FF' }} /> }} />
                    </Text>
                    <Box mb={32}>
                        {reasons.map(({ title, shortKey }) => (
                            <S.ReasonItem
                                key={shortKey}
                                onClick={handleSelectReason(shortKey)}
                                $isSelected={shortKey === reason}
                                data-locator={isFreezeSubscription ? 'freezeSubOption' : 'cancelSubOption'}
                            >
                                <S.RadioChecker $isSelected={shortKey === reason} className="radio-checker" />
                                <Text bold text={t(title)} />
                            </S.ReasonItem>
                        ))}
                    </Box>

                    <div ref={cancellationReasonBtn}>
                        <Button
                            disabled={!reason}
                            onClick={() => reason && onSubmit(reason)}
                            mb={20}
                            text="basics.continue"
                            dataLocator="obContinue"
                        />
                    </div>
                    <OutlinedButton
                        text={t('subscription.cancelDialog.discard')}
                        onClick={handleDiscard}
                        dataLocator="keepSubBtn"
                    />
                </Box>
            </S.Container>
        </Modal>
    );
};

export default CancellationReasonModal;
