import * as action from './actions';

import * as actionTypes from './actionTypes';

import { UserStore } from 'types/store/userStore';
import { InferValueTypes } from 'types/commonTypes';

const initialState: UserStore = {
    country: localStorage.getItem('country') ?? undefined,
    isCloseInformationModal: false,
    urlParams: null,
};

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const userReducer = (state: UserStore = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.RESET_USER_DATA:
            return { ...initialState };

        case actionTypes.UPDATE_USER_SUCCESS:

        case actionTypes.GET_CURRENT_USER_SUCCESS:
            return { ...state, ...(action.payload as Record<string, unknown>) };

        case actionTypes.SET_IS_CLOSE_INFORMATION_MODAL:
            return { ...state, isCloseInformationModal: action.payload };

        case actionTypes.SET_URL_PARAMS: {
            const stringifyData = JSON.stringify(action.payload);

            localStorage.setItem('urlParams', stringifyData);

            return { ...state, urlParams: action.payload };
        }

        default:
            return state;
    }
};

export default userReducer;
