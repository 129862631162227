import { createSelector } from 'reselect';
import { RootReducer } from '../rootReducer';

export const selectSubscriptions = (state: RootReducer) => state.subscriptions;

const selectCancellationCandidate = createSelector(
    selectSubscriptions,
    (subscriptions: any) => subscriptions.cancellationCandidate
);

export { selectCancellationCandidate };
