import React from 'react';

import { SkeletonBlock } from './styled';

// types
import { SkeletonProps } from './types';

const Skeleton = ({ width, height, mb, borderRadius, isBlockElement }: SkeletonProps) => {
    return (
        <SkeletonBlock
            $width={width}
            $height={height}
            $mb={mb}
            $borderRadius={borderRadius}
            $isBlockElement={isBlockElement}
        />
    );
};

export default Skeleton;
