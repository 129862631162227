import { FC } from 'react';

import { Text } from 'components/library';

import * as S from './styled';

interface CirceProps {
    id: number;
}

const Circle: FC<CirceProps> = ({ id }) => {
    return (
        <S.Circle>
            <Text type="small-text" color="on-primary">
                {id}
            </Text>
        </S.Circle>
    );
};

export default Circle;
