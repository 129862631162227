import Guide from 'pages/Guides/subComponents/Guide';

import { Text } from 'components/library';

import * as S from './styled';

import { IGuide } from 'types/guide';

const GuidesList = ({ guides, title, dataLocator }: { title: string; guides: IGuide[]; dataLocator?: string }) => (
    <>
        <Text type="caption" color="text-secondary-subdued" bold text={title} mb={8} />
        <S.GuidesList>
            {guides.map(({ name, preview, download_url: downloadUrl }: IGuide, index) => (
                <S.GuidesListItem key={name} data-locator={`${dataLocator}${index}`}>
                    <Guide title={name} img={preview} link={downloadUrl} />
                </S.GuidesListItem>
            ))}
        </S.GuidesList>
    </>
);

export default GuidesList;
