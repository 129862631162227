import { Navigate } from 'react-router-dom';

import { PUBLIC, PRIVATE } from 'router/constants/routes';

import { useSelector } from 'hooks';

const RootRedirect = () => {
    const { isComplete, isAuthenticated } = useSelector((state) => state.auth);

    return <Navigate replace to={isComplete && isAuthenticated ? PRIVATE.MAIN.path : PUBLIC.LOGIN.path} />;
};

export default RootRedirect;
