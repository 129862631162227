import styled from 'styled-components';

export const Button = styled.button<{ $mb?: string | number }>`
    margin: 0 auto ${({ $mb = 0 }) => $mb}px;
    display: block;
    text-transform: uppercase;
    background: transparent;
    padding: 4px;
    cursor: pointer;
`;
