import { Trans, useTranslation } from 'react-i18next';
import React from 'react';

import { setCancellationCandidate } from 'store/subscriptions/actions';
import { openModal, closeModal } from 'store/modals/actions';

import { sendAnalyticFreezeAlertLoad } from 'services/analytics/trackers/subscriptions';

import { useDispatch } from 'hooks';

import AlertModal from 'modals/AlertModal';

import { ModalName } from 'types/modals';

const FreezeAlertModalContainer = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onSubmit = () => {
        dispatch(openModal(ModalName.REASON_MODAL));
    };

    const onFreezeSubscription = () => {
        dispatch(openModal(ModalName.FREEZE_REASON_MODAL));

        sendAnalyticFreezeAlertLoad();
    };

    const discardCancellationHandler = () => {
        dispatch(closeModal());

        dispatch(setCancellationCandidate(null));
    };

    return (
        <AlertModal
            onDiscard={discardCancellationHandler}
            onSubmit={onSubmit}
            onFreezeSubscription={onFreezeSubscription}
            captions={{
                title: (
                    <Trans
                        i18nKey="subscription.suggestFreeze.title"
                        components={{ 1: <span style={{ color: '#1975FF' }} /> }}
                    />
                ),
                description: <Trans i18nKey="subscription.suggestFreeze.description" />,
                submitButton: t('subscription.text.cancel'),
                discardButton: t('subscription.cancelDialog.discard'),
                freezeButton: t('subscription.text.freeze'),
            }}
            dataLocator="freezeAlertModal"
            isFreezeSubscription
        />
    );
};

export default FreezeAlertModalContainer;
