import { PRODUCT_CODES } from 'constants/payments';

import WellCubeIcon from '../img/wellCubeLogo.svg';
import WalkfitIcon from '../img/walkfitlogo.svg';
import VipSupportIcon from '../img/vipSupportIcon.svg';
import MealPlanIcon from '../img/mealPlanLogo.svg';

import { ProductCode } from '../types';

const getSubscriptionIcon = (productCode: ProductCode) => {
    switch (productCode) {
        case PRODUCT_CODES.APP_MEAL_PLAN:
            return MealPlanIcon;

        case PRODUCT_CODES.APP_VIP_SUPPORT:
            return VipSupportIcon;

        case PRODUCT_CODES.APP_WELLCUBE:
            return WellCubeIcon;

        default:
            return WalkfitIcon;
    }
};

export default getSubscriptionIcon;
