import { Main } from 'wikr-core-analytics';

export const sendAnalyticAccountDeleteClick = () => {
    Main.customData('account_delete_click');
};

export const sendAnalyticAccountDeleteSuccess = () => {
    Main.customData('account_delete_success');
};

export const sendAnalyticAccountDeleteError = () => {
    Main.customData('account_delete_error');
};
