import { BLACK_LIST_HANDLED_ERROR_NAMES, IGNORE_ERRORS_LIST, IGNORE_URLS_LIST } from 'sentry-utils';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { EventHint, SeverityLevel, Event } from '@sentry/react';

import { checkIsOldDevices, getCustomTags, checkFbBots, getAmazonDefaultData } from './helpers';

import { ISentry, IError, ErrorType, Extras, UserInfo, Tags } from './types';

const BLACK_LIST_HANDLED_ERROR_NAMES_LOCAL = [
    ...BLACK_LIST_HANDLED_ERROR_NAMES,
    "undefined is not an object (evaluating 'this.socket.unsubscribe')",
    "Cannot read properties of undefined (reading 'Domain')",
];

const IGNORE_ERRORS_LIST_LOCAL = [
    ...IGNORE_ERRORS_LIST,
    "Can't find variable: __AutoFillPopupClose__",
    /.*Non-Error promise rejection captured'.*/g,
];

export default class SentryClient {
    sentryDSN: string;
    env: 'stage' | 'prod' | string;
    release?: string;
    tracesSampleRate?: number;
    private isInited: boolean;
    private sentry: typeof Sentry;

    constructor({ sentryDSN, env, release, tracesSampleRate }: ISentry) {
        this.sentryDSN = sentryDSN;
        this.env = env || 'stage';
        this.release = release || '1.0.0';
        this.tracesSampleRate = tracesSampleRate || 1.0;
        this.isInited = false;
        this.sentry = Sentry;
    }

    init() {
        if (this.isInited) return;

        Sentry.init({
            dsn: this.sentryDSN,
            integrations: [new Integrations.BrowserTracing()],
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 0.5,
            environment: this.env,
            debug: this.env !== 'prod',
            release: this.release,
            tracesSampleRate: this.tracesSampleRate,
            maxBreadcrumbs: 10,
            beforeSend: (event: Event, hint: EventHint) => SentryClient.filterEventsBeforeSend(event, hint),
            ignoreErrors: IGNORE_ERRORS_LIST_LOCAL || [],
            denyUrls: IGNORE_URLS_LIST || [],
        });

        this.isInited = true;
    }

    public logError(
        error: IError | unknown,
        type: ErrorType,
        errorLevel: SeverityLevel,
        extras?: Extras,
        customTags?: Tags
    ) {
        const customTagsArr = customTags || getCustomTags(error, type, extras);

        return SentryClient.getAnalyticData().then((analyticData) =>
            this.sentry.withScope((scope: Sentry.Scope) => {
                extras && scope.setExtras({ ...extras, ...analyticData });

                scope.setTag('ERROR_TYPE', type);
                scope.setTag('ERROR_LEVEL', errorLevel);
                scope.setTag('aws_id', analyticData?.aws_id || null);
                scope.setLevel(errorLevel);

                customTagsArr.length && customTagsArr.forEach(([tag, value]) => scope.setTag(tag, value));

                this.sentry.captureMessage(SentryClient.prepareErrorByCode(error, type));
            })
        );
    }

    public setUser(userData: UserInfo) {
        this.sentry.setUser({ ...userData });
    }

    private static prepareErrorByCode(error: IError | unknown, type: ErrorType) {
        return `[${type}] | : ${JSON.stringify(error)} | ${error}`;
    }

    private static async getAnalyticData() {
        return await getAmazonDefaultData();
    }

    private static filterEventsBeforeSend(event: Event, hint: EventHint) {
        const error = hint.originalException;

        if (
            (typeof error === 'string' && SentryClient.matchSubstring(error)) ||
            checkIsOldDevices(event) ||
            checkFbBots()
        ) {
            return null;
        }

        return event;
    }

    private static matchSubstring(error: string) {
        return BLACK_LIST_HANDLED_ERROR_NAMES_LOCAL.some((item) => error.includes(item));
    }
}
