import * as actionTypes from './actionTypes';

import { IGuide } from 'types/guide';

export const fetchGuides = () => {
    return {
        type: actionTypes.FETCH_GUIDES,
    } as const;
};

export const setGuides = (payload: IGuide[] | null) => {
    return {
        type: actionTypes.SET_GUIDES,
        payload,
    } as const;
};

export const setIsError = (payload: boolean) => {
    return {
        type: actionTypes.SET_GUIDES_IS_ERROR,
        payload,
    } as const;
};

export const setLoadingState = (payload: boolean) => {
    return {
        type: actionTypes.SET_LOADING_STATE,
        payload,
    } as const;
};

export function setIsShowGuidesNotification(status: boolean | null) {
    return {
        type: actionTypes.SET_IS_SHOW_GUIDES_NOTIFICATION,
        payload: status,
    } as const;
}
