import React from 'react';

import { selectCancellationCandidate } from 'store/subscriptions/selectors';
import { openModal } from 'store/modals/actions';

import { SUBSCRIPTION_PROVIDER } from 'constants/subscriptions';

import {
    sendAnalyticCancelSubscriptionContinueClick,
    sendAnalyticReminderShowClick,
} from 'services/analytics/trackers/subscriptions';

import { useCancelSubscription, useDispatch, useSelector } from 'hooks';

import CancellationReasonModal from 'modals/CancellationReasonModal';

import { ModalName } from 'types/modals';

const CancellationReasonModalContainer = () => {
    const dispatch = useDispatch();

    const { discardCancellation, onCancelSubscription } = useCancelSubscription();
    const cancellationCandidate = useSelector(selectCancellationCandidate);
    const onSubmit = (reason: string) => {
        sendAnalyticCancelSubscriptionContinueClick(reason);
        sendAnalyticReminderShowClick();

        if (cancellationCandidate.paymentProvider === SUBSCRIPTION_PROVIDER.SOLIDGATE) {
            dispatch(openModal(ModalName.REMINDER_MODAL));
        } else {
            onCancelSubscription();
        }
    };

    return (
        <CancellationReasonModal onSubmit={onSubmit} onDiscard={discardCancellation} onClose={discardCancellation} />
    );
};

export default CancellationReasonModalContainer;
