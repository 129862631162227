import { call, takeLatest, all, put, delay } from 'redux-saga/effects';

import api from 'api';

import {
    getCurrentUserRequest,
    getCurrentUserSuccess,
    getCurrentUserError,
    forgotPasswordRequest,
    forgotPasswordSuccess,
    forgotPasswordError,
    updateUserRequest,
    updateUserSuccess,
    updateUserError,
    changePasswordRequest,
    changePasswordError,
    changePasswordSuccess,
    updateUserEmail,
} from './actions';
import { notifyError, notifySuccess } from '../notifications/actions';

import {
    GET_CURRENT_USER_REQUEST,
    UPDATE_USER_REQUEST,
    FORGOT_PASSWORD_REQUEST,
    CHANGE_PASSWORD_REQUEST,
    UPDATE_USER_EMAIL,
} from './actionTypes';

import { UserStore } from 'types/store/userStore';

export function* getCurrentUser({ payload }: ReturnType<typeof getCurrentUserRequest>) {
    try {
        const userData: UserStore = yield call(api.user.getUser);

        yield put(getCurrentUserSuccess(userData));

        payload?.onSuccess?.(userData);
    } catch (error: any) {
        yield put(notifyError('message.error.somethingWentWrong'));
        yield put(getCurrentUserError(error.error));
        payload?.onError?.(error);
    }
}

function* forgotPassword({ payload: { email, onSuccess, onError } }: ReturnType<typeof forgotPasswordRequest>) {
    try {
        const userData: UserStore = yield call(api.user.resetPassword, { email });

        yield put(forgotPasswordSuccess(userData));

        onSuccess && onSuccess();
    } catch (error: any) {
        yield put(notifyError('message.error.somethingWentWrong'));
        yield put(forgotPasswordError(error.error));

        onError && onError('message.error.somethingWentWrong');
    }
}

function* changePassword({ payload: { payload, onSuccess, onError } }: ReturnType<typeof changePasswordRequest>) {
    try {
        yield call(api.user.changePassword, payload);

        yield put(changePasswordSuccess());

        onSuccess && onSuccess();
    } catch (error: any) {
        yield put(changePasswordError(error.error));

        onError && onError();
    }
}

function* updateUser({ payload: { payload, onSuccess, onError } }: ReturnType<typeof updateUserRequest>) {
    try {
        const userData: UserStore = yield call(api.user.update, payload);

        yield put(updateUserSuccess(userData));
        yield put(notifySuccess('message.success.changesSaved'));

        onSuccess && onSuccess();
    } catch (error: any) {
        yield put(updateUserError(error.error));
        yield put(notifyError('errorModal.description'));

        onError && onError(error);
    }
}

function* updateUserEmailRequest({ payload: { email, onSuccess, onError } }: ReturnType<typeof updateUserEmail>) {
    try {
        const userData: UserStore = yield call(api.user.update, {
            email,
        });

        yield put(updateUserSuccess(userData));
        yield put(notifySuccess('change.email.success'));

        // Fix closing notification on redirect
        yield delay(500);

        onSuccess && onSuccess();
    } catch (error: any) {
        yield put(updateUserError(error.error));
        yield put(notifyError(error?.error?.error || 'change.email.error'));

        onError && onError();
    }
}

export default function* watchUser() {
    yield all([
        takeLatest(GET_CURRENT_USER_REQUEST, getCurrentUser),
        takeLatest(UPDATE_USER_REQUEST, updateUser),
        takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword),
        takeLatest(CHANGE_PASSWORD_REQUEST, changePassword),
        takeLatest(UPDATE_USER_EMAIL, updateUserEmailRequest),
    ]);
}
