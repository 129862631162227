import React from 'react';

import Modal from 'components/Modal';
import { Text, Box, Image } from 'components/library';

import * as S from './styled';

import { IWaitingModal } from 'types/modals';

import sandClock from './images/sandClock.svg';

const WaitingModal = ({ captions, dataLocator }: IWaitingModal) => (
    <Modal isOpen dataLocator={dataLocator}>
        <Box paddingTop={20} paddingBottom={20}>
            <Image center maxWidth={46} src={sandClock} alt="sandClock" mb={12} />
            <Text type="h5" bold center mb={24} text={captions.title} />
            <Box>
                <S.Spinner />
            </Box>
        </Box>
    </Modal>
);

export default WaitingModal;
