import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'api';

import { getDeepLinkError, getDeepLinkSuccess } from './actions';

import { GET_DEEP_LINK_REQUEST } from './actionTypes';

import { sendAnalyticDeeplinkLoadClick } from 'services/analytics';

import { IDeepLinkData } from 'types/user/userApiInterface';

function* getDeepLink() {
    try {
        const deepLink: IDeepLinkData = yield call(api.user.deepLinks);

        yield put(getDeepLinkSuccess(deepLink.url));

        sendAnalyticDeeplinkLoadClick();
    } catch (error: any) {
        yield put(getDeepLinkError(error.error));
    }
}

export default function* watchDeepLink() {
    yield all([takeLatest(GET_DEEP_LINK_REQUEST, getDeepLink)]);
}
