import styled from 'styled-components';

// theme
import { color } from 'theme/selectors';

export const Container = styled.div`
    position: relative;
    border-bottom: 1px solid ${color('border-default')};
    padding: 24px;
`;

export const ReturnBack = styled.div`
    position: absolute;
    top: 16px;
    left: 16px;
`;

export const CloseButton = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
`;
