import * as actionTypes from './actionTypes';

import { ModalName } from 'types/modals';

export const openModal = (modalId: ModalName, modalProps?: any) => {
    return {
        type: actionTypes.OPEN_MODAL,
        payload: { modalId, modalProps },
    } as const;
};

export const closeModal = () => {
    return {
        type: actionTypes.CLOSE_MODAL,
    } as const;
};
