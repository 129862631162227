import React from 'react';

import { openModal } from 'store/modals/actions';

import { sendAnalyticFreezePeriodClick } from 'services/analytics/trackers/subscriptions';

import { useCancelSubscription, useDispatch } from 'hooks';

import FreezePeriodModal from 'modals/FreezePeriodModal';

import { ModalName } from 'types/modals';

const FreezePeriodModalContainer = () => {
    const dispatch = useDispatch();

    const { discardCancellation } = useCancelSubscription();

    const onSubmit = (period: string) => {
        sendAnalyticFreezePeriodClick(period);

        dispatch(openModal(ModalName.FREEZE_NOT_AVAILABLE_MODAL));
    };

    return <FreezePeriodModal onSubmit={onSubmit} onDiscard={discardCancellation} onClose={discardCancellation} />;
};

export default FreezePeriodModalContainer;
