export enum accountTabs {
    Profile = 'profile',
    Subscription = 'subscription',
    Settings = 'settings',
}

export const TABS = [
    { id: accountTabs.Profile, title: 'account.tabsTitles.profile', dataLocator: 'profileSettingsTab' },
    { id: accountTabs.Subscription, title: 'account.tabsTitles.subscriptions', dataLocator: 'subscriptionSettingsTab' },
    { id: accountTabs.Settings, title: 'account.tabsTitles.accountSettings', dataLocator: 'accountSettingsTab' },
];
