import React from 'react';

import OverlineButton from 'components/OverlineButton';
import OutlinedButton from 'components/OutlinedButton';
import Modal from 'components/Modal';
import { Text } from 'components/library';

import * as S from './styled';

import warningFreeze from 'assets/img/warningBlue.svg';
import warning from 'assets/img/warning.svg';

import { IAlertModal } from 'types/modals';

// TODO: rewrite approach with using t and Trans (all subscription modals)
const AlertModal = ({
    onSubmit,
    onDiscard,
    onFreezeSubscription,
    captions,
    dataLocator,
    isFreezeSubscription,
    withoutIcon = false,
}: IAlertModal) => {
    return (
        <Modal isOpen onClose={onDiscard} closeOnOverlayClick dataLocator={dataLocator}>
            {!withoutIcon && <S.Img src={isFreezeSubscription ? warningFreeze : warning} alt="warning" />}
            <Text type="h5" center mb={12}>
                {captions.title}
            </Text>
            <Text center color="text-secondary" mb={32}>
                {captions.description}
            </Text>

            {onFreezeSubscription && captions.freezeButton && (
                <OutlinedButton
                    onClick={onFreezeSubscription}
                    text={captions.freezeButton}
                    mb={16}
                    dataLocator="cancelSubFreeze"
                />
            )}
            {onSubmit && captions.submitButton && (
                <OutlinedButton onClick={onSubmit} text={captions.submitButton} mb={20} dataLocator="cancelSubTrue" />
            )}
            {onDiscard && captions.discardButton && (
                <OverlineButton onClick={onDiscard} text={captions.discardButton} mb={4} dataLocator="cancelSubFalse" />
            )}
        </Modal>
    );
};

export default AlertModal;
