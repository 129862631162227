import { Trans } from 'react-i18next';
import React from 'react';

import Skeleton from 'components/Skeleton';

import * as S from './styled';

import { ReactComponent as AppleLogo } from './img/appleLogo.svg';
import { ReactComponent as AndroidLogo } from './img/androidLogo.svg';

import { DownloadButtonProps } from './types';

const DownloadButton = ({ onClick, link, isIos, isLoading, isUnsuitableOS }: DownloadButtonProps) => {
    const icon = isIos ? <AppleLogo /> : <AndroidLogo />;

    return isLoading ? (
        <Skeleton height={64} mb={12} />
    ) : (
        <S.DownloadButton
            onClick={onClick}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            $centered={!isUnsuitableOS}
            data-locator="DeeplinkBtn"
        >
            <Trans i18nKey="main.button" />
            {isUnsuitableOS && icon}
        </S.DownloadButton>
    );
};

export default DownloadButton;
