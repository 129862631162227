import video from './video';
import links from './links';
import installApp from './installApp';
import faqLinks from './faqLinks';
import base from './base';

export default {
    video,
    installApp,
    links,
    base,
    faqLinks,
};
