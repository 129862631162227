import styled from 'styled-components';
import { Link } from 'react-router-dom';

// theme
import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const HeaderContainer = styled.header`
    min-height: 56px;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid ${color('border-default')};
    position: sticky;
    background-color: ${color('surface-main')};
    z-index: 111;

    ${mediaQueries.desktop} {
        padding: 11px 40px;
    }
`;

export const HeaderPublicContainer = styled(HeaderContainer)`
    border: none;

    ${mediaQueries.tabletLandscape} {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
        border-bottom: 1px solid ${color('border-default')};
    }
`;

export const AvatarImage = styled.img`
    width: 24px;
    cursor: pointer;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    max-width: 50%;
    button {
        min-width: 24px;
    }
`;

export const LogInLink = styled(Link)`
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 0 6px;

    & > p {
        font-size: 12px;
        font-weight: 700;
    }
`;
