import React from 'react';

import { CheckCircle, CrossCircle } from 'components/Icon';

const ACTIVE_COLOR = 'success-default';
const INACTIVE_COLOR = 'border-default';

const StatusIcon = ({ isActive, isCancelled }: { isActive: boolean; isCancelled?: boolean }) => {
    if (isCancelled) {
        return <CrossCircle color="critical-default" />;
    }

    return <CheckCircle color={isActive ? ACTIVE_COLOR : INACTIVE_COLOR} />;
};

export default StatusIcon;
