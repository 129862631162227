import { withTheme } from 'styled-components';
import React from 'react';

import { Theme, Colors } from 'components/library';

// types

const AlertIcon = ({ theme, color, size = 20 }: { theme: Theme; color?: Colors; size?: number }) => {
    const iconColor = theme[color || 'critical-default'];

    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.4999 9.99995C17.4999 14.1421 14.1421 17.4999 9.99995 17.4999C5.85779 17.4999 2.5 14.1421 2.5 9.99995C2.5 5.85779 5.85779 2.5 9.99995 2.5C14.1421 2.5 17.4999 5.85779 17.4999 9.99995"
                stroke={iconColor}
                strokeWidth="1.59999"
                strokeMiterlimit="10"
            />
            <path
                d="M9.12829 13.3803C9.12829 13.8619 9.51856 14.2521 9.99994 14.2521C10.4813 14.2521 10.8716 13.8619 10.8716 13.3803C10.8716 12.899 10.4813 12.5088 9.99994 12.5088C9.51856 12.5088 9.12829 12.899 9.12829 13.3803Z"
                fill={iconColor}
            />
            <path
                d="M10 6.44141V10.6461"
                stroke={iconColor}
                strokeWidth="1.59999"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default withTheme(AlertIcon);
