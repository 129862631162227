import { ActionEventAction, ElementEventAction } from 'wikr-core-analytics';

import { trackClick } from './mainTrackers';

export const sendAnalyticResetPassEmailClick = () => {
    trackClick({
        elementEvent: ElementEventAction.input,
        actionEvent: ActionEventAction.email,
    });
};

export const sendAnalyticResetPassButtonClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.sendPassword,
    });
};

export const sendAnalyticResetPassContactUsClick = () => {
    trackClick({
        elementEvent: ElementEventAction.text,
        actionEvent: ActionEventAction.contactUs,
    });
};

export const sendAnalyticResetPassLoginButtonClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.login,
    });
};

export const sendAnalyticResetPassResendPasswordClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.resendPassword,
    });
};
