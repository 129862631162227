import {
    SUBSCRIPTION_ACTIVE,
    SUBSCRIPTION_REDEMPTION,
    SUBSCRIPTION_CANCELLED,
    SUBSCRIPTION_PENDING,
    SUBSCRIPTION_CHARGEBACK,
} from 'constants/payments';

import { ISubscription } from 'types/subscription';

const activeSubscriptionState = [
    SUBSCRIPTION_ACTIVE,
    SUBSCRIPTION_REDEMPTION,
    SUBSCRIPTION_PENDING,
    SUBSCRIPTION_CHARGEBACK,
];

const canceledSubscriptionState = [SUBSCRIPTION_CANCELLED];

type UseSubscriptionsCountRes = {
    activeCount: number;
    canceledCount: number;
};

const useSubscriptionsCount = (subscriptions: ISubscription[] = []): UseSubscriptionsCountRes => {
    const { activeCount, canceledCount } = subscriptions.reduce(
        (acc, s) => {
            if (
                activeSubscriptionState.includes(s.state) &&
                !s.cancelled_at &&
                !(s.internal_cancel_at && s.state === SUBSCRIPTION_ACTIVE)
            ) {
                acc.activeCount += 1;
            }

            if (
                canceledSubscriptionState.includes(s.state) ||
                s.cancelled_at ||
                (s.internal_cancel_at && s.state === SUBSCRIPTION_ACTIVE)
            ) {
                acc.canceledCount += 1;
            }

            return acc;
        },
        { activeCount: 0, canceledCount: 0 }
    );

    return {
        activeCount,
        canceledCount,
    };
};

export default useSubscriptionsCount;
