import { ActionEventAction, ElementEventAction, EventTriggerFixed, Main } from 'wikr-core-analytics';

import { trackClick, trackEventFixed } from './mainTrackers';

export const sendAnalyticCancelSubscriptionClick = () => {
    trackClick({
        elementEvent: ElementEventAction.text,
        actionEvent: ActionEventAction.cancelSubscription,
    });
};

export const sendAnalyticSubscriptionCancelSuccess = () => {
    trackEventFixed(EventTriggerFixed.subscriptionCancelSuccess);
};

export const sendAnalyticSubscriptionCancelError = () => {
    Main.customData('subscription_cancel_error');
};

export const sendAnalyticSubscriptionCancelRequest = () => {
    Main.customData('front_cancel_subscription_request');
};
export const sendAnalyticSubscriptionKeepClick = () => {
    Main.customData('account_subscriptions__click', { event_action: 'text__keep_subscription' });
};

export const sendAnalyticCancelSubscriptionContinueClick = (reason: string) => {
    Main.customData('account_subscriptions__click', { event_action: 'button__continue_cancel', event_label: reason });
};

export const sendAnalyticFreezeAlertLoad = () => {
    Main.customData('freeze_sub__screen__load', { event_action: 'popup__load' });
};

export const sendAnalyticFreezeReasonClick = (reason: string) => {
    Main.customData('freeze_sub__click', { event_action: 'button__quiz_continue', event_label: reason });
};

export const sendAnalyticFreezePeriodClick = (period: string) => {
    Main.customData('freeze_sub__click', { event_action: 'button__continue', event_label: period });
};

export const sendAnalyticReminderShowClick = () => {
    Main.customData('reminder_show');
};

export const sendAnalyticSetReminderClick = () => {
    Main.customData('reminder_click');
};
