import { useTranslation } from 'react-i18next';
import React from 'react';

import config from 'config';

import { useModals } from 'hooks';

import Modal from 'components/Modal/Modal';
import { Box, Button, Image, Text } from 'components/library';

import doneImane from './img/done.svg';

const AccountWasDeletedSuccessContainer = () => {
    const { t } = useTranslation();
    const { closeModal } = useModals();

    return (
        <Modal isOpen onClose={closeModal}>
            <Box paddingTop={20} paddingBottom={20} paddingX={16}>
                <Image center maxWidth={46} src={doneImane} alt="sandClock" mb={12} />
                <Text type="h5" bold center mb={24} text={t('account.settings.deleteAccount.successModal.title')} />
                <Text
                    type="medium-text"
                    center
                    mb={24}
                    text={t('account.settings.deleteAccount.successModal.subTitle')}
                />
                <a href={config.WALKFIT_ONBOARDING}>
                    <Button
                        onClick={closeModal}
                        mb={20}
                        text="account.settings.deleteAccount.successModal.takeQuiz"
                        dataLocator="obTakeQiuz"
                    />
                </a>
            </Box>
        </Modal>
    );
};

export default AccountWasDeletedSuccessContainer;
