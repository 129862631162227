import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useFormik } from 'formik';

import { FORGOT_PASSWORD } from 'store/user/actionTypes';
import { forgotPasswordRequest } from 'store/user/actions';
import { createProcessingSelector } from 'store/loading/selectors';

import { sendAnalyticResetPassButtonClick, sendAnalyticResetPassEmailClick } from 'services/analytics';

import { useDispatch, useSelector } from 'hooks';

import { isEmpty } from 'helpers/dataStructuresUtils';

import { Box, Button, Text } from 'components/library';
import FormikInput from 'components/FormikInput';
import ContactUsLink from 'components/ContactUsLink';
import { CheckMailBox } from './subComponents';

import emailIcon from 'assets/img/icons/emailIcon.svg';

import { ForgotPasswordSchema } from './validationSchema';

type FormValue = {
    email: string;
};

const ForgotPasswordForm = ({ email = '' }: { email?: string }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isEmailSent, setSentStatus] = useState(false);

    const isLoading: boolean = useSelector(createProcessingSelector([FORGOT_PASSWORD]));

    const { errors, dirty, values, setFieldTouched, handleSubmit, setFieldValue, touched, setFieldError } = useFormik({
        initialValues: { email },
        validationSchema: ForgotPasswordSchema,
        onSubmit: (formValues: FormValue) => {
            sendAnalyticResetPassButtonClick();

            dispatch(
                forgotPasswordRequest({
                    email: formValues.email,
                    onSuccess: () => {
                        setSentStatus(true);
                    },
                    onError: (message?: string) => {
                        setFieldError('email', message);
                    },
                })
            );
        },
    });

    if (isEmailSent) {
        return <CheckMailBox email={values.email} onResendPassword={() => setSentStatus(false)} />;
    }

    return (
        <Box paddingX={16}>
            <Text type="h5" center mb={12} text={t('forgotPassword.title')} />
            <Text text={t('forgotPassword.subtitle')} center mb={20} />
            <form>
                <Box mb={44}>
                    <FormikInput
                        type="email"
                        field="email"
                        setFieldTouched={setFieldTouched}
                        initialValues={values}
                        setFieldValue={setFieldValue}
                        onInputFocus={sendAnalyticResetPassEmailClick}
                        touched={touched}
                        values={values}
                        errors={errors}
                        leftIcon={emailIcon}
                        label="input.email.placeholder"
                        dataLocator="forgotPasswordEmailInput"
                        placeholder="input.email.placeholder"
                    />
                </Box>
                <Button
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    disabled={!dirty || !isEmpty(errors)}
                    mb={24}
                    text={t('forgotPassword.sendPassword')}
                    dataLocator="forgotPasswordSendPassBtn"
                />
            </form>
            <ContactUsLink />
        </Box>
    );
};

export default ForgotPasswordForm;
