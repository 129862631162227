import { Trans } from 'react-i18next';
import { FC } from 'react';

import { Text } from 'components/library';

import * as S from './styled';

import { RadioProps } from './types';

const Radio: FC<RadioProps> = ({ checked, label, onChange, name, value, dataLocator }) => {
    const id = `radio_${name}_${value}`;

    return (
        <S.RadioWrapper data-locator={dataLocator}>
            <S.Input type="radio" name={name} onChange={onChange} id={id} value={value} checked={checked} />
            <S.RadioLabel htmlFor={id}>
                <S.Radio />
                <Text type="medium-text" color="text-secondary">
                    <Trans i18nKey={label} />
                </Text>
            </S.RadioLabel>
        </S.RadioWrapper>
    );
};

export default Radio;
