import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 60px 16px 16px;
`;

export const Icon = styled.img`
    margin: 0 auto 10px;
    max-width: 70px;
`;

export const Email = styled.strong`
    word-wrap: break-word;
`;
