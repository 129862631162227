import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Box } from 'components/library';

export const Container = styled(Box)`
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
`;

export const ForgotPasswordLink = styled(Link)`
    display: block;
    margin: 0 auto;
    width: fit-content;

    & > p {
        display: inline;
    }
`;
