import styled from 'styled-components';

export const FooterButton = styled.div`
    width: 150px;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const LoaderContainer = styled.div`
    margin: 42px auto;
    display: flex;
    justify-content: center;
`;
