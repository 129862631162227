import { selectModalProps } from 'store/modals/selectors';
import { closeModal as closeModalAction, openModal as openModalAction } from 'store/modals/actions';

import { useDispatch, useSelector } from 'hooks';

import { ModalName } from 'types/modals';

export const useModals = <T>() => {
    const dispatch = useDispatch();
    const modalProps: T = useSelector(selectModalProps);

    const openModal = (modalId: ModalName, props?: any) => {
        dispatch(openModalAction(modalId, props));
    };
    const closeModal = () => {
        dispatch(closeModalAction());
    };

    return { openModal, closeModal, modalProps };
};
