export const reasons = [
    {
        title: 'subscription.cancellationReason.forgetCancel',
        shortKey: 'avoid_renewal',
    },
    {
        title: 'subscription.cancellationReason.indoor',
        shortKey: 'not_find_indoor',
    },
    {
        title: 'subscription.cancellationReason.workout',
        shortKey: 'not_find_advertised',
    },
    {
        title: 'subscription.cancellationReason.noTime',
        shortKey: 'no_time',
    },
    {
        title: 'subscription.cancellationReason.tooExpensive',
        shortKey: 'too_expensive',
    },
    {
        title: 'subscription.cancellationReason.tooDifficult',
        shortKey: 'difficult_to_follow',
    },
    {
        title: 'subscription.cancellationReason.motivation',
        shortKey: 'no_motivation',
    },
    {
        title: 'subscription.cancellationReason.health',
        shortKey: 'health_condition',
    },
    {
        title: 'subscription.cancellationReason.other',
        shortKey: 'other',
    },
];

export const freezeReasons = [
    {
        title: 'subscription.reasonFreeze.option1',
        shortKey: 'fell_ill',
    },
    {
        title: 'subscription.reasonFreeze.option2',
        shortKey: 'cant_follow_now',
    },
    {
        title: 'subscription.reasonFreeze.option3',
        shortKey: 'no_use',
    },
    {
        title: 'subscription.reasonFreeze.option4',
        shortKey: 'start_later',
    },
    {
        title: 'subscription.reasonFreeze.option5',
        shortKey: 'other',
    },
];
