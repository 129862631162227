import styled, { css } from 'styled-components';

export const PageWrapper = styled.div<{ $isOpenSidebar: boolean }>`
    ${({ $isOpenSidebar }) =>
        $isOpenSidebar &&
        css`
            position: fixed;
            top: -${window.scrollY}px;
            right: 0;
            left: 0;
        `}
`;
