import { SUBSCRIPTION_TITLE } from 'constants/subscriptions';
import { PRODUCT_CODES } from 'constants/payments';

const getSubscriptionName = (currentProductCode: string) => {
    const isAppFullAccess = currentProductCode === PRODUCT_CODES.APP_FULL_ACCESS;
    const isVipSupport = currentProductCode === PRODUCT_CODES.APP_VIP_SUPPORT;
    const isWellcube = currentProductCode === PRODUCT_CODES.APP_WELLCUBE;

    if (isVipSupport) return SUBSCRIPTION_TITLE.APP_VIP_SUPPORT;

    if (isAppFullAccess) return SUBSCRIPTION_TITLE.APP_FULL_ACCESS;

    if (isWellcube) return SUBSCRIPTION_TITLE.APP_WELLCUBE;

    return SUBSCRIPTION_TITLE.OTHER;
};

export default getSubscriptionName;
