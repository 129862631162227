import { useTranslation } from 'react-i18next';
import React from 'react';

import { STORE_LINKS } from 'constants/values';

import { Image, Text } from 'components/library';
import { Footer } from 'components/Footer';
import StoreButton from './subComponents/StoreButton';
import QRCodeComponent from './subComponents/QRCodeComponent';
import { DownloadSteps } from './subComponents/DownloadSteps';
import DownloadButton from './subComponents/DownloadButton';
import { CheckCircle } from '../../components/Icon';

import * as S from './styled';

import imgIos from './img/ImgIos@4x.png';
import imgAnd from './img/ImgAndroid@4x.png';
import downloadLinkImg from './img/downloadLink.png';
import arrowImg from './img/Arrow.png';

import { IInstallAppContainerProps } from './types';

const InstallAppContainer = ({
    isLoading,
    handleDownloadApp,
    deepLinkUrl,
    isIos,
    isUnsuitableOS,
}: IInstallAppContainerProps) => {
    const { t } = useTranslation();
    const downloadLabel = isIos ? 'downloadSteps.downloadLabelIOs' : 'downloadSteps.downloadLabelAndroid';

    const iosLink = isUnsuitableOS ? STORE_LINKS.ios : deepLinkUrl;
    const androidLink = isUnsuitableOS ? STORE_LINKS.android : deepLinkUrl;

    return (
        <S.Container>
            <Text type="h6" medium center text={t('downloadSteps.title')} />

            <S.StepContainer>
                <S.MobileWrapper mb={40}>
                    <Text type="h4" medium text={t('downloadSteps.titleInPanel')} mb={40} />
                    <Image center maxWidth={193} alt="app-img" src={isIos ? imgIos : imgAnd} />
                    {isUnsuitableOS && (
                        <DownloadButton
                            onClick={handleDownloadApp}
                            link={iosLink}
                            isIos
                            isLoading={isLoading}
                            isUnsuitableOS
                        />
                    )}
                    <DownloadButton
                        onClick={handleDownloadApp}
                        link={isIos ? iosLink : androidLink}
                        isIos={isIos}
                        isLoading={isLoading}
                        isUnsuitableOS={isUnsuitableOS}
                    />
                </S.MobileWrapper>
                <S.StepSection>
                    <S.TabletWrapper>
                        <Text type="h3" medium text={t('downloadSteps.titleInPanel')} mb={40} />
                    </S.TabletWrapper>

                    <DownloadSteps />

                    <Text text={t('downloadSteps.afterStepsExp')} mb={40} />
                </S.StepSection>
                <S.TabletWrapper>
                    <S.DownloadWrapper>
                        <S.ArrowImg alt="arrow-img" src={arrowImg} />
                        <Image center maxWidth={390} mb={20} alt="download-link-img" src={downloadLinkImg} />
                        <S.QRWrapper>
                            <QRCodeComponent isLoading={isLoading} deepLinkUrl={deepLinkUrl} />
                        </S.QRWrapper>
                    </S.DownloadWrapper>
                </S.TabletWrapper>
                <S.DownloadLabelText type="h5" medium text={t(downloadLabel)} mb={24} />
                <S.MobileWrapper>
                    {isUnsuitableOS && (
                        <StoreButton
                            isIOS
                            isLoading={isLoading}
                            deepLinkUrl={iosLink}
                            onClick={handleDownloadApp}
                            isMobile
                            dataLocator="AppleStoreLink"
                        />
                    )}
                    <StoreButton
                        isIOS={isIos}
                        isLoading={isLoading}
                        deepLinkUrl={isIos ? iosLink : androidLink}
                        onClick={handleDownloadApp}
                        isMobile
                        dataLocator={isIos ? 'AppleStoreLink' : 'GoogleStoreLink'}
                    />
                </S.MobileWrapper>
                <S.TabletWrapper>
                    <S.StoreWrapper mb={24}>
                        <StoreButton deepLinkUrl={androidLink} isLoading={isLoading} onClick={handleDownloadApp} />
                        <StoreButton deepLinkUrl={iosLink} isLoading={isLoading} onClick={handleDownloadApp} isIOS />
                    </S.StoreWrapper>
                </S.TabletWrapper>
                <S.BottomBanner>
                    <CheckCircle color="success-default" />
                    <Text type="medium-text" text={t('downloadSteps.footerText')} />
                </S.BottomBanner>
            </S.StepContainer>
            <Footer />
        </S.Container>
    );
};

export default InstallAppContainer;
