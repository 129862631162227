import { Box } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { selectSubscriptions } from 'store/subscriptions/selectors';
import { setSubscriptionCancelReminder } from 'store/subscriptions/actions';
import { openModal } from 'store/modals/actions';

import { sendAnalyticSetReminderClick } from 'services/analytics';

import { useCancelSubscription, useDispatch, useSelector } from 'hooks';

import { reminderReasons } from 'containers/modals/ReminderReasonModalContainer/constatnts';
import ReminderReason from 'containers/modals/ReminderReasonModalContainer/components/ReminderReason/ReminderReason';
import ReasonCard from 'containers/modals/ReminderReasonModalContainer/components/ReasonCard';

import OutlinedButton from 'components/OutlinedButton';
import ModalHeader from 'components/ModalHeader';
import Modal from 'components/Modal';
import { Button, Text } from 'components/library';

import * as S from './styled';

import { ModalName } from 'types/modals';

const ReminderReasonModalContainer = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { discardCancellation, onCancelSubscription } = useCancelSubscription();

    const { cancellationCandidate } = useSelector(selectSubscriptions);

    const setReminder = () => {
        sendAnalyticSetReminderClick();
        dispatch(openModal(ModalName.WAITING_MODAL));
        dispatch(
            setSubscriptionCancelReminder({
                externalId: cancellationCandidate!.externalId,
                paymentProvider: cancellationCandidate!.paymentProvider,
                onError: () => {
                    dispatch(openModal(ModalName.ERROR_MODAL, { tryAgain: setReminder }));
                },
                onSuccess: () => {
                    dispatch(openModal(ModalName.REMINDER_HAS_BEEN_SET_MODAL));
                },
                onCancel: () => null,
            })
        );
    };

    const goBack = () => {
        dispatch(openModal(ModalName.REASON_MODAL));
    };

    return (
        <Modal isOpen fullscreen customStyles={{ content: { overflow: 'scroll' } }}>
            <ModalHeader onBack={goBack} onClose={discardCancellation} />
            <S.Container>
                <Box paddingTop={20} paddingBottom={100} paddingX={16}>
                    <Text type="h4" center mb={24} text={t('subscription.reminderModal.title')} />
                    <Text type="large-text" center mb={24} text={t('subscription.reminderModal.subTitle')} />

                    {reminderReasons.map((reason) => (
                        <ReminderReason badge={reason.badge} title={reason.title} key={reason.title}>
                            {reason.children.map((reasonSubList) => {
                                if (reasonSubList.type === 'card') {
                                    return (
                                        <ReasonCard
                                            key={reasonSubList.title}
                                            title={reasonSubList.title || ''}
                                            badge={reasonSubList.badge || ''}
                                            bodyText={reasonSubList.bodyText}
                                        />
                                    );
                                } else {
                                    return (
                                        <Text
                                            type="small-text"
                                            text={t(reasonSubList.bodyText)}
                                            key={reasonSubList.bodyText}
                                        />
                                    );
                                }
                            })}
                        </ReminderReason>
                    ))}
                    <Button
                        onClick={setReminder}
                        mb={20}
                        text={t('subscription.reminderModal.button.setReminder')}
                        dataLocator="obContinue"
                    />
                    <OutlinedButton
                        text={t('subscription.text.cancel')}
                        onClick={onCancelSubscription}
                        dataLocator="keepSubBtn"
                    />
                </Box>
            </S.Container>
        </Modal>
    );
};

export default ReminderReasonModalContainer;
