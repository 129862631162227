import { AUTH_TOKEN_NAME } from 'constants/api';

import BrowserStorage from './browserStorage';

export const getToken = (): string | null => {
    return BrowserStorage.getItem(AUTH_TOKEN_NAME);
};

export const removeToken = (): void => {
    BrowserStorage.removeItem(AUTH_TOKEN_NAME);
};

export const setToken = (token: string): void => {
    BrowserStorage.setItem(AUTH_TOKEN_NAME, token);
};
