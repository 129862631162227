import { useSelector } from 'hooks';

import { IGuide } from 'types/guide';

export const useGuides = () => {
    const { userGuides, isLoading, isError } = useSelector((state) => state.guides);

    const purchasedGuides = (userGuides as IGuide[]).filter((guide: IGuide) => guide.is_purchased);

    return {
        isLoading,
        userGuides,
        purchasedGuides,
        isError,
    };
};
