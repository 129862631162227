import { RouterProvider } from 'react-router-dom';

import Notifications from 'containers/Notifications';

import ErrorBoundary from 'components/ErrorBoundary';

import ThemeProvider from './theme/provider';
import router from './router';

function App() {
    return (
        <ThemeProvider>
            <Notifications />
            <ErrorBoundary>
                <RouterProvider router={router} />
            </ErrorBoundary>
        </ThemeProvider>
    );
}

export default App;
