import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { setIsShowGuidesNotification } from 'store/guides/actions';

import { useGuides, useDispatch } from 'hooks';

import ErrorMessageModal from 'modals/ErrorMessageModal';

import { Text } from 'components/library';
import Guide from './subComponents/Guide';

import * as S from './styled';

import guidesIcon from './assets/guidesModalIcon.webp';

import GuidesSkeleton from './GuidesSkeleton';
import GuidesList from './GuidesList';
import { bonusGuides } from './bonusGuides';

const Guides = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { userGuides, purchasedGuides, isLoading, isError } = useGuides();

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    useEffect(() => {
        dispatch(setIsShowGuidesNotification(false));
    }, []);

    useEffect(() => {
        setIsErrorModalOpen(isError);
    }, [isError]);

    return (
        <S.Container>
            <Text type="h5" center mb={12}>
                <Trans i18nKey="guides.title" />
            </Text>
            <Text type="medium-text" center mb={24}>
                <Trans i18nKey="guides.messageSent" />
            </Text>
            <S.GuidesListWrapper>
                {isLoading && <GuidesSkeleton />}

                {Boolean(purchasedGuides.length) && (
                    <GuidesList
                        guides={purchasedGuides}
                        title={t('guides.subtitle.purchased')}
                        dataLocator="purchasedGuide"
                    />
                )}

                {Boolean(bonusGuides.length) && (
                    <GuidesList guides={bonusGuides} title={t('guides.subtitle.bonus')} dataLocator="bonusGuide" />
                )}

                {!userGuides?.['length'] && !Boolean(bonusGuides.length) && !isLoading && (
                    <Guide title={t('guides.notFound')} />
                )}
            </S.GuidesListWrapper>

            <ErrorMessageModal
                isOpen={isErrorModalOpen}
                onClose={() => setIsErrorModalOpen(false)}
                description={t('guides.error.description')}
                icon={guidesIcon}
            />
        </S.Container>
    );
};

export default Guides;
