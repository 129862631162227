import { put, takeLatest, all, call } from 'redux-saga/effects';

import api from 'api';

import { setGuides, setLoadingState, setIsError } from './actions';
import { notifyError } from '../notifications/actions';

import { FETCH_GUIDES } from './actionTypes';

import { IGuide } from 'types/guide';

function* getGuides() {
    try {
        yield put(setIsError(false));

        const data: IGuide[] = yield call(api.user.getProducts);

        if (data) {
            yield put(setGuides(data));
        }
        yield put(setLoadingState(false));
    } catch (error) {
        notifyError('getGuides error');

        yield put(setLoadingState(false));
        yield put(setIsError(true));
    }
}

export default function* watchGuides() {
    yield all([takeLatest(FETCH_GUIDES, getGuides)]);
}
