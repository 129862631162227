import { ActionEventAction, ElementEventAction, EventTrigger, EventTriggerFixed, Main } from 'wikr-core-analytics';

import { changePathNameToScreenId } from '../helpers';

interface TrackClickData {
    elementEvent?: ElementEventAction;
    actionEvent?: ActionEventAction;
    screenIdParam?: string;
    eventLabel?: string | string[];
    customEventData?: string;
}

export const trackScreenLoad = () => {
    const screenId = changePathNameToScreenId();

    Main.trackNew({
        eventData: { event: EventTrigger.screenLoad, screenId },
        actionData: {
            elementEvent: ElementEventAction.screen,
            actionEvent: ActionEventAction.load,
        },
    });
};

export const trackEventFixed = (event: EventTriggerFixed, eventLabel?: string | string[]) => {
    Main.trackNew({
        eventData: event,
        ...(eventLabel && { data: { event_label: eventLabel } }),
    });
};

export const trackClick = ({
    elementEvent,
    actionEvent,
    screenIdParam,
    eventLabel,
    customEventData,
}: TrackClickData) => {
    const screenId = screenIdParam ? screenIdParam : changePathNameToScreenId();
    let actionData = null;

    if (customEventData) actionData = customEventData;
    if (elementEvent && actionEvent) actionData = { elementEvent, actionEvent };

    Main.trackNew({
        eventData: { event: EventTrigger.click, screenId },
        ...(actionData && { actionData }),
        ...(eventLabel && { data: { event_label: eventLabel } }),
    });
};
