import { withTheme } from 'styled-components';
import React from 'react';

import { Theme, Colors } from 'components/library';

// types

const CheckCircle = ({ theme, color }: { theme: Theme; color?: Colors }) => {
    const fill = theme[color || 'success-default'];

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <circle cx="12" cy="12" r="11.4483" fill={fill} stroke="#53BC84" strokeWidth="1.10345" />
            <path
                d="M15.8397 8.44737C16.1048 8.19895 16.5201 8.20861 16.7734 8.46909L17.0808 8.78521C17.319 9.03011 17.3335 9.41541 17.1145 9.67753L11.9948 15.8033C11.4733 16.4273 10.5146 16.4285 9.99158 15.8058L6.92455 12.1541C6.6894 11.8742 6.72363 11.457 7.00126 11.2191L7.49229 10.7984C7.73897 10.587 8.1021 10.5842 8.35201 10.7918L10.0897 12.2349C10.6067 12.6643 11.3626 12.6417 11.8531 12.1822L15.8397 8.44737Z"
                fill="white"
            />
        </svg>
    );
};

export default withTheme(CheckCircle);
