export default {
    inline: {
        '--background-color': '#FF4F64',
        '--brand-primary': '#F94E50',
        '--brand-secondary': '#4E69F9',
        '--brand-primary-light': '#4E69F9',
        '--some': '#000',
    },
    theme: {
        'primary-default': '#DC4B4B',
        'primary-hovered': '#F05C5C',
        'primary-active': '#CE4141',
        'primary-disabled': '#F9B3B0',

        'secondary-default': '#FF964F',
        'secondary-hovered': '#FFA466',
        'secondary-active': '#F9802F',
        'secondary-disabled': '#FFCAA7',

        'on-primary': '#FFFFFF',

        'text-main': '#292E33',
        'text-secondary': '#454E57',
        'text-secondary-subdued': '#8696A6',
        'border-default': '#D4DAE0',
        'border-subdued': '#F3F5F7',

        'surface-main': '#F7F6F3',
        'surface-secondary': '#EEE9DC',
        'surface-default': '#FFF',
        'surface-hovered': '#FDF3E0',

        'success-default': '#53BC84',
        'success-highlight': '#A0E3BF',
        'success-surface': '#FFFFFF',

        'warning-default': '#FEE14B',
        'warning-highlight': '#FFF9E9',
        'warning-surface': '#FFF9E9',

        'critical-default': '#FF4F64',
        'critical-highlight': '#FFC2CA',
        'critical-surface': '#FEF5F5',

        // this var need for avoid TS error, in future will be updating
        'action-main': '#525558',
        'action-secondary': '',
        'action-hovered': '',
        'action-pressed': '',
        'action-disabled': '',

        discount: '#FF4F64',
        rating: '#D4B444',
        safe: '#1975FF',
        'safe-surface': '#F0F5FE',

        'default-indent': '16',
        'border-radius-small': '8',
        'border-radius-medium': '12',
        'default-box-shadow': '0 8px 16px rgba(47, 198, 73, 0.25)',
    },
};
