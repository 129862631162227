import React from 'react';

import { Image } from 'components/library';
import ForgotPasswordForm from 'components/ForgotPasswordForm';

import * as S from 'assets/styles/authStyled';
import loginImage from 'assets/img/loginImage.webp';

const ForgotPassword = () => {
    return (
        <S.AuthContainer>
            <S.AuthImage>
                <Image src={loginImage} alt="reset-password" isForceVisible />
            </S.AuthImage>
            <S.AuthForm>
                <ForgotPasswordForm />
            </S.AuthForm>
        </S.AuthContainer>
    );
};

export default ForgotPassword;
