import { GENDER_FIELD } from 'constants/api';

import BrowserStorage from './browserStorage';

export const setGender = (gender: string): void => {
    BrowserStorage.setItem(GENDER_FIELD, gender);
};

export const removeGender = (): void => {
    BrowserStorage.removeItem(GENDER_FIELD);
};

export const removePersistRoot = (): void => {
    BrowserStorage.removeItem('persist:root');
};
