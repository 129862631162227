import { FC } from 'react';

import * as S from './styled';

import { StepProps } from './types';

import { Circle } from '../Circle';

const Step: FC<StepProps> = ({ id, children }) => {
    return (
        <S.Container>
            <Circle id={id} />
            {children}
        </S.Container>
    );
};

export default Step;
