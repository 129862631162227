import { useSelector } from 'react-redux';

import { selectIsCloseInformationModal } from 'store/user/selectors';
import { selectGuidesInfo } from 'store/guides/selectors';

import { configInformationModal } from './config';

export const useConfigInformationModal = () => {
    const isCloseInformationModal = useSelector(selectIsCloseInformationModal);
    const { isShowGuidesNotification } = useSelector(selectGuidesInfo);

    if (isCloseInformationModal) return null;

    const { guide } = configInformationModal;

    if (isShowGuidesNotification) return guide;
};
