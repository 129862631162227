import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PUBLIC } from 'router/constants/routes';

import { sendAnalyticLoginHeaderClick } from 'services/analytics';

import { useSelector } from 'hooks';

import Logo from 'components/Logo';
import { Text } from 'components/library';

import * as S from './styled';

const HeaderPublic = () => {
    const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const isRoot = pathname === PUBLIC.LOGIN.path;

    return (
        <S.HeaderPublicContainer>
            <S.TitleContainer>
                <Logo />
            </S.TitleContainer>

            {!isRoot && !isAuthenticated && (
                <S.LogInLink to="/" onClick={sendAnalyticLoginHeaderClick}>
                    <Text color="text-main" type="small-button" text={t('basics.login')} />
                </S.LogInLink>
            )}
        </S.HeaderPublicContainer>
    );
};

export default HeaderPublic;
