import { NavLink, useLocation } from 'react-router-dom';
import { memo } from 'react';

import { sendAnalyticSideBarElementClick } from 'services/analytics';

import { linkType, SidebarLinkProps } from '../types';

import SidebarLinkButton from './SidebarLinkButton';

const SidebarLink = ({
    to,
    title,
    onClick,
    dataLocator,
    type,
    target = '_self',
    rel,
    external = false,
    isPurchasedAdditionalProduct,
    analyticActionEvent,
    customEventData,
    badge,
}: SidebarLinkProps) => {
    const handleClick = () => {
        sendAnalyticSideBarElementClick({ actionEvent: analyticActionEvent, customEventData });

        onClick && onClick(to);
    };

    const { pathname } = useLocation();

    const isActive = pathname === to || pathname.startsWith(`${to}/`);
    const isPrimary = linkType.Primary === type;

    const commonLinkProps = {
        'data-locator': dataLocator,
        onClick: handleClick,
        target,
        rel,
    };

    if (external) {
        return (
            <a href={to} {...commonLinkProps}>
                <SidebarLinkButton isActive={isActive} isPrimary={isPrimary} title={title} badge={badge} />
            </a>
        );
    }

    return (
        <NavLink to={to} {...commonLinkProps}>
            <SidebarLinkButton
                isActive={isActive}
                isPrimary={isPrimary}
                title={title}
                isPurchasedAdditionalProduct={isPurchasedAdditionalProduct}
                badge={badge}
            />
        </NavLink>
    );
};

export default memo(SidebarLink);
