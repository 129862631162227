import React, { useState, useEffect } from 'react';

import BrowserStorage from 'api/utils/browserStorage';

import { selectCurrentUser } from 'store/user/selectors';
import { setIsCloseInformationModal } from 'store/user/actions';
import { createProcessingSelector } from 'store/loading/selectors';
import { selectDeepLink } from 'store/deepLink/selectors';
import { GET_DEEP_LINK } from 'store/deepLink/actionTypes';
import { getDeepLinkRequest } from 'store/deepLink/actions';

import { DEVICE_OS } from 'constants/values';

import { sendAnalyticDownloadAppClick } from 'services/analytics';

import { useDispatch, useSelector } from 'hooks';
import { useConfigInformationModal } from './hooks';

import { getDeviceOS } from 'helpers/utils';
import { getVideoLink } from 'helpers/getVideoLink';

import InstallAppContainer, { ErrorInstallAppPage } from 'containers/InstallAppContainer';

import { UserStore } from 'types/store/userStore';

const InstallApp = () => {
    const isLoading: boolean = useSelector(createProcessingSelector([GET_DEEP_LINK]));
    const deepLinkUrl = useSelector(selectDeepLink);
    const currentUser: UserStore = useSelector(selectCurrentUser);

    const dispatch = useDispatch();
    const configInformationModal = useConfigInformationModal();

    const videoLink = getVideoLink();

    const deviceOS = getDeviceOS();
    const isIos = deviceOS === DEVICE_OS.IOS;
    const isUnsuitableOS = deviceOS !== DEVICE_OS.IOS && deviceOS !== DEVICE_OS.ANDROID;

    const [isAppDownloaded, setIsAppDownloaded] = useState(BrowserStorage.getItem('isAppDownloaded'));

    const handleDownloadApp = () => {
        setIsAppDownloaded(true);
        BrowserStorage.setItem('isAppDownloaded', true);

        const eventLabel = isIos ? 'ios' : 'android';

        sendAnalyticDownloadAppClick(eventLabel);
    };

    const onCancelModal = () => {
        dispatch(setIsCloseInformationModal(true));
    };

    useEffect(() => {
        if (!deepLinkUrl) {
            dispatch(getDeepLinkRequest());
        }
    }, []);
    if (deepLinkUrl !== 'error') {
        return (
            <InstallAppContainer
                isAppDownloaded={isAppDownloaded}
                isLoading={isLoading}
                handleDownloadApp={handleDownloadApp}
                deepLinkUrl={deepLinkUrl}
                isIos={isIos}
                isUnsuitableOS={isUnsuitableOS}
                videoLink={videoLink}
                configInformationModal={configInformationModal}
                onCancelModal={onCancelModal}
                userName={currentUser?.name}
            />
        );
    } else {
        return <ErrorInstallAppPage />;
    }
};

export default InstallApp;
