import React from 'react';
import QRCode from 'qrcode.react';

import Skeleton from 'components/Skeleton';
import { Box } from 'components/library';

import * as S from './styled';

import { QRCodeProps } from './types';

const QRCodeComponent = ({ isLoading, deepLinkUrl }: QRCodeProps) => {
    return (
        <S.ScanQrCodeWrapper>
            {isLoading ? (
                <Skeleton />
            ) : (
                <Box paddingX={5} paddingTop={5} paddingBottom={5} backgroundColor="on-primary" borderRadius={12}>
                    <QRCode size={140} value={deepLinkUrl} />
                </Box>
            )}
        </S.ScanQrCodeWrapper>
    );
};

export default QRCodeComponent;
