import styled, { keyframes } from 'styled-components';

import { color } from 'theme/selectors';

const spin = keyframes`
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
`;

export const Spinner = styled.div`
    position: relative;
    width: 24px;
    height: 24px;
    display: block;
    margin: 0 auto 32px;

    &:before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0deg);
        width: 24px;
        min-height: 24px;
        border: 3px solid ${color('border-default')};
        border-left: 3px solid ${color('text-secondary-subdued')};
        border-radius: 50%;
        box-sizing: border-box;
        animation: ${spin} 1.1s infinite linear;
        transition: opacity 0.3s;
    }
`;
