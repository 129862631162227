import { withTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { Fragment } from 'react';

import { selectCurrentUser } from 'store/user/selectors';
import { openModal } from 'store/modals/actions';

import { sendAnalyticAccountDeleteClick } from 'services/analytics/trackers/accountDelete';

import { useSelector } from 'hooks';

import OutlinedButton from 'components/OutlinedButton';
import { Box, Input, Theme } from 'components/library';

import * as S from './styled';

import { InputType } from 'types/ui';
import { UserStore } from 'types/store/userStore';
import { ModalName } from 'types/modals';

import { getInputsData } from './config';

const SettingsTab = ({ theme }: { theme: Theme }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user: UserStore = useSelector(selectCurrentUser);

    const { t } = useTranslation();

    return (
        <Box paddingTop={40}>
            {getInputsData(user.email).map(
                ({ field, icon, value, label, buttonRoute, dataLocator, dataLocatorButton, text }) => (
                    <Fragment key={label}>
                        <S.StyledInput data-locator={dataLocator}>
                            <Input
                                name={field}
                                label={label}
                                touched
                                leftIcon={icon}
                                disabled
                                isFocus
                                value={value}
                                initialValue={value}
                                onChange={() => {}}
                                theme={theme}
                                type={field as InputType}
                            />
                        </S.StyledInput>

                        <S.SmallButton
                            dataLocator={dataLocatorButton}
                            mb={40}
                            onClick={() => navigate(buttonRoute)}
                            text={text}
                        />
                    </Fragment>
                )
            )}
            <S.DeleteAccountButton>
                <OutlinedButton
                    dataLocator="deleteAccountButton"
                    mb={40}
                    onClick={() => {
                        sendAnalyticAccountDeleteClick();
                        dispatch(openModal(ModalName.DELETE_ACCOUNT));
                    }}
                    text={t('account.settings.button.deleteAccount')}
                />
            </S.DeleteAccountButton>
        </Box>
    );
};

export default withTheme(SettingsTab);
