import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';
import React from 'react';

import { NOTIFY_STATUSES } from 'constants/notifications';

import { Text } from 'components/library';
import { ErrorIcon } from 'components/Icon';

import { ReactComponent as CheckCircle } from 'assets/img/check-circle-outline.svg';

const Message = ({ message, dataLocator }: { message: string; dataLocator: string }) => (
    <div data-locator={dataLocator}>
        <Text type="small-text" color="text-secondary">
            <Trans i18nKey={message} />
        </Text>
    </div>
);

const showNotify = ({ message, status = NOTIFY_STATUSES.DEFAULT }: { message: string; status: NOTIFY_STATUSES }) => {
    if (status === NOTIFY_STATUSES.SUCCESS) {
        return toast.success(<Message message={message} dataLocator="successChangesAlertbox" />, {
            icon: <CheckCircle />,
        });
    }
    if (status === NOTIFY_STATUSES.ERROR) {
        return toast.error(<Message message={message} dataLocator="failedChangesAlertbox" />, {
            icon: <ErrorIcon />,
        });
    }

    return toast(<Message message={message} dataLocator="successChangesAlertbox" />);
};

export default showNotify;
