import { Provider } from 'wikr-core-analytics';
import { call, takeLatest, all, put } from 'redux-saga/effects';

import { setToken, removeToken } from 'api/utils/tokenManagement';
import { removeGender, removePersistRoot, setGender } from 'api/utils/storageHandlers';
import api from 'api';

import {
    signInRequest,
    signInSuccess,
    authentication,
    setAuthenticationStatus,
    authenticationBySignature,
    setAuthRedirectUrl,
} from './actions';
import { getCurrentUserSuccess, resetUserData, setIsCloseInformationModal } from '../user/actions';
import { notifyError } from '../notifications/actions';
import { setIsShowGuidesNotification } from '../guides/actions';

import { SIGN_IN_REQUEST, AUTHENTICATE, AUTHENTICATE_BY_SIGNATURE, LOG_OUT } from './actionTypes';

import { resetLanguage } from 'services/i18n';

import { getParamFromUrl } from 'helpers/utils';

import { UserStore } from 'types/store/userStore';

function* signIn({ payload }: ReturnType<typeof signInRequest>) {
    const { userData, onSuccess, onError } = payload;

    try {
        const userDataResponse: UserStore = yield call(api.user.signIn, userData);

        yield put(getCurrentUserSuccess(userDataResponse));
        yield put(setAuthenticationStatus(true));
        yield put(signInSuccess(userDataResponse));

        userDataResponse.gender && setGender(userDataResponse.gender);
        userDataResponse.language && resetLanguage(userDataResponse.language);

        onSuccess && onSuccess(userDataResponse);
    } catch (error: any) {
        onError?.();

        yield put(notifyError('message.error.somethingWentWrong'));
    }
}

function* authenticate({ payload }: ReturnType<typeof authentication>) {
    const { onSuccess, onError } = payload;

    try {
        const userData: UserStore = yield call(api.user.getUser);

        yield put(getCurrentUserSuccess(userData));
        yield put(setAuthenticationStatus(true));

        onSuccess && onSuccess();

        userData.gender && setGender(userData.gender);
        userData.language && resetLanguage(userData.language);

        yield put(setAuthRedirectUrl(null));
    } catch (error: any) {
        yield put(notifyError('message.error.somethingWentWrong'));
        yield put(setAuthenticationStatus(false));

        onError && onError();
    }
}

function* authenticateBySignature({ payload }: ReturnType<typeof authenticationBySignature>) {
    const { uId, signature, onSuccess, onError } = payload;

    const utmSource = getParamFromUrl('utm_source') || 'default_device';

    localStorage.setItem('utmSource', utmSource);
    Provider.setUTMSource(utmSource);

    try {
        const userData: UserStore = yield call(api.user.getUserBySignature, { uId, signature });

        yield put(getCurrentUserSuccess(userData));
        yield put(setAuthenticationStatus(true));

        userData.gender && setGender(userData.gender);
        userData?.token && setToken(userData.token);

        onSuccess && onSuccess();

        yield put(setAuthRedirectUrl(null));
    } catch (error: any) {
        yield put(setAuthenticationStatus(false));

        onError && onError();
    }
}

function* logout() {
    removeToken();
    removeGender();
    removePersistRoot();

    yield put(setAuthenticationStatus(false));
    yield put(resetUserData());

    yield put(setIsShowGuidesNotification(null));
    yield put(setIsCloseInformationModal(false));
}

export default function* watchAuth() {
    yield all([
        takeLatest(LOG_OUT, logout),
        takeLatest(SIGN_IN_REQUEST, signIn),
        takeLatest(AUTHENTICATE, authenticate),
        takeLatest(AUTHENTICATE_BY_SIGNATURE, authenticateBySignature),
    ]);
}
