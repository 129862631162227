import { useTranslation } from 'react-i18next';
import React from 'react';

import { Text } from 'components/library';

import * as S from './styled';

import { ISubscriptionCardCommonProps } from '../types';

import SubscriptionCardWrapper from './SubscriptionCardWrapper';
import SubscriptionCardHeader from './SabscriptionCardHeader';

const ExpiredSubscription = ({ tabIcon, heading, expirationDate }: ISubscriptionCardCommonProps) => {
    const { t } = useTranslation();

    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader icon={tabIcon} heading={heading} isExpired />
            <S.DateInfo>
                <Text type="caption" color="text-secondary-subdued" text={t('subscription.text.expiredOn')} />
                <Text type="caption" color="text-secondary-subdued" text={expirationDate} />
            </S.DateInfo>
        </SubscriptionCardWrapper>
    );
};

export default ExpiredSubscription;
