import { DEVICE_OS } from 'constants/values';

import { getDeviceOS, replaceSlashToDash } from 'helpers/utils';

const getLocaleIdentifier = (country?: null | string) => {
    /* eslint-disable padding-line-between-statements */
    switch (country) {
        case 'US':
        case 'ZA':
        case 'KE':
        case 'CA':
        case 'GH':
            return 'en-US'; // mm-dd-yyyy

        case 'CN':
        case 'JP':
        case 'KR':
        case 'KP':
        case 'TW':
        case 'HU':
        case 'MN':
        case 'BT':
            return 'ja-JP'; // yyyy-mm-dd

        default:
            return 'ua-UA'; // dd-mm-yyyy
    }
};

const transformDateToSlash = (date: string) => {
    const dateWithSlash = new Date(date?.replace(/[.-]/g, '/'));

    return dateWithSlash.getHours() === 0 ? dateWithSlash.setHours(12) : dateWithSlash;
};

const normalizeDateByLocale = (date: string, userCountry?: null | string) => {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'UTC',
        timeZoneName: 'short',
    };

    const deviceOS = getDeviceOS();
    const localeIdentifier = getLocaleIdentifier(userCountry);
    const dateWithSlash = transformDateToSlash(date);

    let localeDate = new Date(dateWithSlash).toLocaleDateString(localeIdentifier, options);

    if (deviceOS === DEVICE_OS.ANDROID && localeIdentifier === 'ua-UA') {
        const baseDate = new Date(dateWithSlash);
        const dd = baseDate.getUTCDate().toString();
        const mm = (baseDate.getUTCMonth() + 1).toString();
        const yyyy = baseDate.getUTCFullYear();

        localeDate = dd?.padStart(2, '0') + '-' + mm?.padStart(2, '0') + '-' + yyyy + ' UTC';
    }

    const dateWithoutComma = localeDate?.replace(/,(?![^,]*,)/m, ' ');

    return replaceSlashToDash(dateWithoutComma);
};

export default normalizeDateByLocale;
