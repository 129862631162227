import config from 'config';

import ApiMethods, { ApiErrorHandler } from './ApiMethods';

interface ConstructorParams {
    apiUrl?: string;
    apiKey?: string;
    isPrivate?: boolean;
    onError?: ApiErrorHandler;
}
export default class ApiClient {
    public: ApiMethods;
    private: ApiMethods;

    constructor({ apiUrl = config.API_URL, onError }: ConstructorParams) {
        this.public = new ApiMethods({ apiUrl, onError });
        this.private = new ApiMethods({ apiUrl, isPrivate: true, onError });
    }
}
