import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ForgotPasswordLink = styled(Link)`
    display: block;
    margin: 0 auto;
    width: fit-content;

    & > p {
        display: inline;
    }
`;
