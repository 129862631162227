import { useTranslation } from 'react-i18next';

import { Text } from 'components/library';

import * as S from './styled';

import { ReactComponent as WellfuneLogo } from 'assets/img/wellfunel.svg';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <S.FooterBox>
            <Text type="caption" color="text-secondary-subdued" medium text={t('footer.madeWithLove')} mb={12} />
            <WellfuneLogo />
        </S.FooterBox>
    );
};

export { Footer };
