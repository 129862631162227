import styled from 'styled-components';

import { color } from 'theme/selectors';

export const Container = styled.div`
    border-radius: 20px;
    border: 1px solid ${color('border-default')};
    padding: 16px;

    img {
        height: 24px;
    }
    margin-bottom: 12px;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
`;
