export const DAYS_PER_WEEK = 7;
export const DAYS_PER_MONTH = 31;
export const DAYS_PER_TWO_MONTH = 62;
export const DAYS_PER_THREE_MONTH = 90;
export const DAYS_PER_FOUR_MONTH = 121;
export const DAYS_PER_FIVE_MONTH = 152;
export const DAYS_PER_SIX_MONTH = 182;
export const DAYS_PER_SEVEN_MONTH = 212;
export const DAYS_PER_EIGHT_MONTH = 243;
export const DAYS_PER_NINE_MONTH = 273;
export const DAYS_PER_TEN_MONTH = 304;
export const DAYS_PER_ELEVEN_MONTH = 334;
export const DAYS_PER_YEAR = 365;

export const PRODUCT_CODES = {
    APP_FULL_ACCESS: 'app_full_access',
    APP_MEAL_PLAN: 'meal_plan',
    APP_VIP_SUPPORT: 'vip_support',
    APP_WELLCUBE: 'wellcube_subs',
    APP_GUIDE_WEIGHT_LOSS: 'weight_loss_gd',
    APP_GUIDE_FAT_BURNING: 'fat_burning_gd',
    APP_GUIDE_ALL: 'fat_burning_gd__weight_loss_gd',
    APP_GUIDE_WEIGHT_LOSS_FULL: 'weight_loss_gd_full',
    APP_GUIDE_FAT_BURNING_FULL: 'fat_burning_gd_full',
    APP_GUIDE_ALL_FULL: 'fat_burning_gd__weight_loss_gd_full',
} as const;

export const SUBSCRIPTION_ACTIVE = 'active';
export const SUBSCRIPTION_REDEMPTION = 'redemption';
export const SUBSCRIPTION_CANCELLED = 'cancelled';
export const SUBSCRIPTION_CHARGEBACK = 'chargeback';
export const SUBSCRIPTION_PENDING = 'pending';
export const SUBSCRIPTION_PENDING_FAILED = 'pending_failed';

export const APP_WELLCUBE = 'wellcube_subs';
export const VIDEO_COURSE_FACELIFT = 'video_course_facelift';
export const VIDEO_COURSE_BACKPAIN = 'video_course_backpain';
export const VIDEO_COURSE_NUTRITION = 'video_course_nutrition';
export const VIDEO_COURSE_HABITS = 'video_course_habits';
export const VIDEO_COURSE_NUTRITION_FULL = 'video_course_nutrition_full';
export const VIDEO_COURSE_FASTING = 'video_course_fasting';
export const VIDEO_COURSE_FASTING_FULL = 'video_course_fasting_full';
export const VIDEO_COURSE_GOALS = 'video_course_goals';
export const VIDEO_COURSE_GOALS_FULL = 'video_course_goals_full';
export const VIDEO_COURSE_SLEEPING = 'video_course_sleeping';
export const VIDEO_COURSE_SLEEPING_FULL = 'video_course_sleeping_full';
export const VIDEO_COURSE_KETO = 'video_course_keto';
export const VIDEO_COURSE_KETO_FULL = 'video_course_keto_full';
export const VIDEO_COURSE_STRESS = 'video_course_stress';
export const VIDEO_COURSE_STRESS_FULL = 'video_course_stress_full';
export const VIDEO_COURSE_MEDITATION = 'video_course_meditation';
export const VIDEO_COURSE_MEDITATION_FULL = 'video_course_meditation_full';
export const VIDEO_COURSE_MOTIVATION = 'video_course_motivation';
export const VIDEO_COURSE_MOTIVATION_FULL = 'video_course_motivation_full';
export const VIDEO_COURSE_SELFESTEEM = 'video_course_selfesteem';
export const VIDEO_COURSE_SELFESTEEM_FULL = 'video_course_selfesteem_full';

export const VIDEO_COURSES_LIST = [
    VIDEO_COURSE_FACELIFT,
    VIDEO_COURSE_BACKPAIN,
    VIDEO_COURSE_NUTRITION,
    VIDEO_COURSE_HABITS,
    VIDEO_COURSE_GOALS,
    VIDEO_COURSE_SLEEPING,
    VIDEO_COURSE_KETO,
    VIDEO_COURSE_STRESS,
    VIDEO_COURSE_MEDITATION,
    VIDEO_COURSE_MOTIVATION,
    VIDEO_COURSE_SELFESTEEM,
    VIDEO_COURSE_FASTING,
    VIDEO_COURSE_NUTRITION_FULL,
    VIDEO_COURSE_FASTING_FULL,
    VIDEO_COURSE_GOALS_FULL,
    VIDEO_COURSE_SLEEPING_FULL,
    VIDEO_COURSE_KETO_FULL,
    VIDEO_COURSE_STRESS_FULL,
    VIDEO_COURSE_MEDITATION_FULL,
    VIDEO_COURSE_MOTIVATION_FULL,
    VIDEO_COURSE_SELFESTEEM_FULL,
    APP_WELLCUBE,
];
