import { EventTriggerFixed } from 'wikr-core-analytics';

import { trackEventFixed } from './mainTrackers';

export const sendAnalyticEmailUpdate = () => {
    trackEventFixed(EventTriggerFixed.emailUpdateSuccess);
};

export const sendAnalyticPasswordUpdate = () => {
    trackEventFixed(EventTriggerFixed.passwordUpdateSuccess);
};

export const sendAnalyticProfileUpdateSuccess = (updatedFields: string[]) => {
    trackEventFixed(EventTriggerFixed.profileUpdateSuccess, updatedFields);
};

export const sendAnalyticProfileUpdateError = () => {
    trackEventFixed(EventTriggerFixed.profileUpdateError);
};
