import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    margin: 13px 0;
    line-height: 30px;
    white-space: pre-wrap;
    flex-wrap: wrap;
    overflow: scroll;
`;
