import { useNavigate } from 'react-router-dom';

import { PRIVATE } from 'router/constants/routes';

import { selectGuidesInfo } from 'store/guides/selectors';

import { useSelector, useSidebarControl } from 'hooks';

import Logo from 'components/Logo';
import Burger from 'components/Burger';

import * as S from './styled';

import defaultUserPic from 'assets/img/default-avatar.svg';

const Header = () => {
    const navigate = useNavigate();

    const { isShowGuidesNotification } = useSelector(selectGuidesInfo);
    const { isOpen, toggle, close } = useSidebarControl();

    const handleAvatarNavigate = () => {
        navigate(PRIVATE.ACCOUNT_PROFILE.path);

        close();
    };

    return (
        <S.HeaderContainer>
            <S.TitleContainer>
                <Burger onClick={toggle} isActive={isOpen} isEnableInfoIndicator={isShowGuidesNotification} />
                <Logo />
            </S.TitleContainer>
            <S.AvatarImage onClick={handleAvatarNavigate} src={defaultUserPic} />
        </S.HeaderContainer>
    );
};

export default Header;
