import { useTranslation } from 'react-i18next';
import React from 'react';

import OutlinedButton from 'components/OutlinedButton/OutlinedButton';

import { ICancelButton } from '../types';

const CancelButton = ({ onCancel }: ICancelButton) => {
    const { t } = useTranslation();

    return (
        <OutlinedButton
            onClick={onCancel}
            data-locator="cancelSubscriptionButton"
            text={t('subscription.text.cancel')}
        />
    );
};

export default CancelButton;
