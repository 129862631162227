import { SENTRY_AXIOS, ERROR_LEVELS } from 'sentry-utils';
import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';

import config from 'config';

import { FRONT_HASH_URL, USER_SIGNATURE_URL } from './constants/urls';
import EXCEPTION_ERROR_CODES from './constants/errorCodes';

import sentry from 'services/Sentry/SentryInstance';

import { getToken, setToken } from './utils/tokenManagement';
import { getLanguage } from './utils/languageManagment';
import { isAxiosError } from './utils/axiosHandlers';

import { IErrorResponse, TMPOptions, ApiErrorData } from './types/response';
import { IRequestOptions } from './types/request';

const PLATFORM_CODE = 3;

export type ApiErrorHandler = (errorData: ApiErrorData) => void;

interface ConstructorParams {
    apiUrl?: string;
    apiKey?: string;
    isPrivate?: boolean;
    onError?: ApiErrorHandler;
    productApiId?: string;
}

export default class ApiMethods {
    apiUrl: string;
    apiVersion: string;
    apiKey: string;
    isPrivate: boolean;
    instance: AxiosInstance;
    requiredHeaders: string[];
    exclusionEndpoints: string[];
    productApiId: string;
    onError?: ApiErrorHandler;

    constructor({
        apiUrl = config.API_URL,
        apiKey = config.API_KEY,
        isPrivate = false,
        onError,
        productApiId = config.PRODUCT_API_ID,
    }: ConstructorParams) {
        this.apiUrl = apiUrl;
        this.apiVersion = config.API_VERSION;
        this.apiKey = apiKey;
        this.isPrivate = isPrivate;
        this.productApiId = productApiId;
        this.requiredHeaders = ['version', 'x-api-key', 'language', 'platform'];
        this.exclusionEndpoints = [USER_SIGNATURE_URL, FRONT_HASH_URL];
        this.onError = onError;
        this.instance = this.createAxiosInstance();
    }

    createAxiosInstance() {
        const axiosInstance: AxiosInstance = axios.create({
            baseURL: this.apiUrl,
        });

        axiosInstance.defaults.headers.common['version'] = this.apiVersion;
        axiosInstance.defaults.headers.common['x-api-key'] = this.apiKey;
        axiosInstance.defaults.headers.common['language'] = getLanguage();
        axiosInstance.defaults.headers.common['platform'] = PLATFORM_CODE;
        axiosInstance.defaults.headers.common['project-id'] = this.productApiId;

        return axiosInstance;
    }

    createTmpOptions<RequestData>(options: IRequestOptions<RequestData>, token: string | null) {
        const tmpOptions: TMPOptions = { ...options, url: `/${options.url}` };

        tmpOptions.params = tmpOptions.params || {};
        tmpOptions.headers = {
            ...tmpOptions.headers,
            'Content-Type': 'application/json',
            ...(token && this.isPrivate && { token }),
        };

        return tmpOptions;
    }

    headerIsMissing(common: AxiosRequestHeaders) {
        return this.requiredHeaders.some((header) => !common[header]);
    }

    async get<ResponseData>(url: string, params?: Record<string, string>, headers?: Record<string, string | boolean>) {
        return this.request<unknown, ResponseData>({
            url,
            params,
            headers,
            method: 'GET',
            data: {},
        });
    }

    async post<RequestData, ResponseData>(url: string, payload: Partial<RequestData> = {}) {
        return this.request<RequestData, ResponseData>({
            url,
            method: 'POST',
            data: payload,
        });
    }

    async put<RequestData>(url: string, payload: Partial<RequestData> = {}) {
        return this.request<RequestData>({
            url,
            method: 'PUT',
            data: payload,
        });
    }

    async patch<RequestData, ResponseData>(url: string, payload: Partial<RequestData> = {}) {
        return this.request<RequestData, ResponseData>({
            url,
            method: 'PATCH',
            data: payload,
        });
    }

    async delete<RequestData>(url: string, payload: Partial<RequestData> = {}) {
        return this.request<RequestData>({
            url,
            method: 'DELETE',
            data: payload,
        });
    }

    async request<RequestData, ResponseData = never>(options: IRequestOptions<RequestData>): Promise<ResponseData> {
        const token = getToken();

        if (!token && this.isPrivate) {
            if (options.url === 'solidgate/subscription/cancel') {
                const urlParams = localStorage.getItem('urlParams');

                sentry.logError(
                    new Error('Token is required for private method | UNSUBSCRIBE FLOW'),
                    SENTRY_AXIOS,
                    ERROR_LEVELS.CRITICAL,
                    {
                        details: options,
                        urlParams,
                    }
                );
            }
        }

        this.instance.defaults.headers.common['language'] = getLanguage();

        if (token) this.instance.defaults.headers.common['token'] = token;

        const { common } = this.instance.defaults.headers;

        if (this.headerIsMissing(common)) {
            sentry.logError(new Error('Required header is missing'), SENTRY_AXIOS, ERROR_LEVELS.CRITICAL, {
                ...common,
            });

            return Promise.reject(new Error('Required header is missing'));
        }

        const tmpOptions = this.createTmpOptions(options, token);

        try {
            const response = await this.instance(tmpOptions);

            if (response.headers.token) {
                setToken(response.headers.token);
                response.data.token = response.headers.token;
            }
            if (response.headers.country) response.data.country = response.headers.country;

            return Promise.resolve(response.data);
        } catch (error: any) {
            const typedError = error as IErrorResponse;
            const errorResponse = typedError?.response;

            this.onError?.({ error: typedError, tmpOptions });

            if (!isAxiosError(typedError)) {
                sentry.logError(new Error('Untracked error'), SENTRY_AXIOS, ERROR_LEVELS.CRITICAL, {
                    options: tmpOptions,
                    response: errorResponse,
                });

                return Promise.reject(new Error('Untracked error'));
            } else {
                if (!EXCEPTION_ERROR_CODES.includes(error?.code)) {
                    sentry.logError(new Error('General axios error'), SENTRY_AXIOS, ERROR_LEVELS.CRITICAL, {
                        details: error,
                        config: error?.config,
                        options: tmpOptions,
                    });
                }

                return Promise.reject(new Error('Axios error is tracked', { cause: error }));
            }
        }
    }
}
