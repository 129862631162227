import { uaParser } from 'wikr-core-analytics';

import { DEVICE_OS } from 'constants/values';

import { fromEntriesPolyfill } from 'helpers/polyfills';

const UAParser = new uaParser();

export const DEVICE_TYPE_REGEXP_MAP = {
    mobile: new RegExp(
        'Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)',
        'i'
    ),
    tablet: new RegExp('(tablet|ipad|playbook|silk)|(android(?!.*mobi))', 'i'),
};

export const isEmpty = (value: null | undefined | string | number | object): boolean => {
    return (
        value === null ||
        value === undefined ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0) ||
        (typeof value === 'number' && Number(value) === Infinity)
    );
};

export const cleanObject = (object: any) => {
    for (const propName in object) {
        if (object[propName] === null || object[propName] === undefined) {
            delete object[propName];
        }
    }

    return object;
};

export const replaceUnderscoreToDash = (string: string): string => string.replace(/_/g, '-');
export const replaceSlashToDash = (value: string) => value?.replace(/\//g, '-');

export const getDeviceOS = (): string => {
    const userAgent = window.navigator.userAgent;
    const osData = UAParser.setUA(userAgent).getOS();

    return osData?.name?.toUpperCase() || DEVICE_OS.DEFAULT;
};

export const getDeviceBrowser = (): string | undefined => {
    const userAgent = window.navigator.userAgent;
    const browserData = UAParser.setUA(userAgent).getBrowser();

    return browserData?.name;
};

export const getDeviceType = (): 'mobile' | 'tablet' | 'desktop' => {
    const userAgent = window.navigator.userAgent;

    if (DEVICE_TYPE_REGEXP_MAP.tablet.test(userAgent)) {
        return 'tablet';
    }

    if (DEVICE_TYPE_REGEXP_MAP.mobile.test(userAgent)) {
        return 'mobile';
    }

    return 'desktop';
};

export const getDeviceInfo = () => {
    const userAgent = window.navigator.userAgent;
    const userAgentParsed = UAParser.setUA(userAgent);

    const os = userAgentParsed.getOS();
    const browser = userAgentParsed.getBrowser();

    return {
        os: `${os.name} ${os.version}`,
        browser: `${browser.name} ${browser.version}`,
    };
};

export const formattedUrlHelper = (url: string) => {
    return url.replace(/(\/([a-z]{2})\/)|\//g, '');
};

export const replaceToUnderscore = (screen: string) => {
    return screen.replace(/-/g, '_');
};

export const getWindowSize = (params = '(min-width: 1024px)') => {
    const mediaQuery = window.matchMedia(params);

    return mediaQuery.matches;
};

export const getParamFromUrl = (paramName: string) => {
    const params = new URLSearchParams(window.location.search);

    return params.get(paramName);
};

export const getUrlParams = () => {
    const queryParams = window.location.search;

    if (!isEmpty(queryParams)) {
        const urlSearchParams = new URLSearchParams(queryParams)?.entries();

        if (typeof Object.fromEntries === 'function') {
            return Object.fromEntries(urlSearchParams);
        }

        return fromEntriesPolyfill(urlSearchParams);
    }

    return null;
};

export const removeItemFromArrayByValue = (array: Array<any>, value: any) => {
    return array.filter((item: any) => (item.value || item) !== value);
};
