import { CLP } from '../constants/currency';

export const getToFixedNumber = (number: number, numbersAfterComma = 2) => {
    return Number(number.toFixed(numbersAfterComma));
};

export const getPriceFromCents = (price: string | number, currency?: string) => {
    if (currency === CLP.name) return Number(price);

    return getToFixedNumber(Number(price) / 100);
};
