import { useTranslation } from 'react-i18next';
import React from 'react';

import { getFaqLink } from 'helpers/getFaqLink';

import { Text } from 'components/library';

import * as S from './styled';

const SubscriptionHelpButton = () => {
    const { t } = useTranslation();

    return (
        <S.HelpButton>
            <Text type="medium-text" bold text={t('subscription.helpButton.question')} />
            <S.FaqLink href={getFaqLink()} target="_blank" data-locator="faqButton">
                <Text type="caption" color="on-primary" bold text={t('subscription.helpButton.faq')} />
            </S.FaqLink>
        </S.HelpButton>
    );
};

export default SubscriptionHelpButton;
