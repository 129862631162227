import appConfig from 'appConfig';

import { getLanguage } from 'api/utils/languageManagment';

import isMobile from './isMobile';

export const getVideoLink = () => {
    const language = getLanguage();

    if (isMobile.android()) {
        // @ts-ignore
        return appConfig.video.videoLinks.android[language] || appConfig.video.videoLinks.android['en'];
    }

    // @ts-ignore
    return appConfig.video.videoLinks.ios[language] || appConfig.video.videoLinks.ios['en'];
};
