import { Trans, useTranslation } from 'react-i18next';
import React from 'react';

import { Box, Text } from 'components/library';
import ContactUsLink from 'components/ContactUsLink/ContactUsLink';

import * as S from './styled';

const PaymentFailedCard = () => {
    const { t } = useTranslation();

    return (
        <S.FailedContainer>
            <S.FailedIcon />
            <Text type="large-text" medium mb={8} center>
                <Trans i18nKey="subscription.paymentFailed.title" />
            </Text>
            <Text mb={16} center>
                <Trans i18nKey="subscription.paymentFailed.text" />
            </Text>
            <Box paddingTop={10} paddingBottom={20}>
                <ContactUsLink variant="button" text={t('basics.contactSupport')} />
            </Box>
            <Text center>
                <Trans i18nKey="subscription.paymentFailed.text2" />
            </Text>
        </S.FailedContainer>
    );
};

export default PaymentFailedCard;
