import { useTranslation } from 'react-i18next';
import React from 'react';

import { Image, Text } from 'components/library';

import * as S from '../styled';

import { SidebarLinkButtonProps } from '../types';

const SidebarLinkButton = ({
    isActive,
    isPrimary,
    title,
    isPurchasedAdditionalProduct,
    badge,
}: SidebarLinkButtonProps) => {
    const { t } = useTranslation();

    const textType = isPrimary ? 'large-text' : 'medium-text';

    return (
        <S.NavButton>
            <Text type={textType} bold={isActive} text={t(title)} />
            {isPurchasedAdditionalProduct && <S.RedDot />}
            {badge && <Image maxWidth={16} src={badge} alt="badge" />}
        </S.NavButton>
    );
};

export default SidebarLinkButton;
