import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';

const fullscreenContent = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
`;

const defaultContent = css`
    width: 100%;
    max-width: 432px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
`;

export const Content = styled.div<{ $fullscreen?: boolean }>`
    position: relative;
    background-color: ${color('surface-main')};

    ${({ $fullscreen }) => ($fullscreen ? fullscreenContent : defaultContent)}
`;

export const Overlay = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 16px;
    z-index: 1000000000;
    background: rgba(0, 0, 0, 0.5);
`;

export const CloseButton = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`;
