import { useTranslation } from 'react-i18next';

import { PRIVATE } from 'router/constants/routes';

import passwordIcon from './assets/password.svg';
import emailIcon from './assets/email.svg';

export const getInputsData = (email = '') => {
    const { t } = useTranslation();

    return [
        {
            field: 'email',
            icon: emailIcon,
            value: email,
            label: t('input.email.placeholder'),
            buttonRoute: PRIVATE.CHANGE_EMAIL.path,
            dataLocator: 'emailInputDisabled',
            dataLocatorButton: 'changeEmailBtn',
            text: t('account.settings.button.changeEmail'),
        },
        {
            field: 'text',
            icon: passwordIcon,
            value: '••••••••',
            label: t('input.password.placeholder'),
            buttonRoute: PRIVATE.CHANGE_PASSWORD.path,
            dataLocator: 'passwordInputDisabled',
            dataLocatorButton: 'changePasswordBtn',
            text: t('account.settings.button.changePassword'),
        },
    ];
};
