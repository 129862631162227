import { Main } from 'wikr-core-analytics';

import BrowserStorage from 'api/utils/browserStorage';

import { getAgeFromBirthday } from 'helpers/date';

import { IUserInfoResponse } from 'types/user/userApiInterface';

export const setUserProperties = async ({
    user_id,
    language,
    country,
    gender,
    fitness_level,
    goal,
    age,
    weight,
    target_weight,
    height,
    current_bodytype,
    target_bodytype,
    target_zone,
}: Partial<IUserInfoResponse>) => {
    Main.setUserProperties({
        user_id,
        language,
        country,
        gender,
        fitness_level,
        goal,
        age: getAgeFromBirthday(age),
        weight,
        target_weight,
        height,
        current_bodytype,
        target_bodytype,
        target_zones: target_zone,
        client_id: BrowserStorage.getItem('uuid'),
        aws_id: (await Main.getDefaultAmazonAnalyticPurchaseData())?.aws_id,
    });
};
