import styled from 'styled-components';

import { Button } from 'components/library';

import { color } from 'theme/selectors';

export const SmallButton = styled(Button)`
    padding: 8px 12px;
    min-height: unset;
    min-width: unset;
    width: auto;

    * {
        letter-spacing: unset;
        text-transform: unset;
        font-size: 12px;
        line-height: 16px;
    }
`;

// todo: rm after padding issue in lib will be fixed
export const StyledInput = styled.div`
    > * {
        margin-bottom: 20px;
    }

    & label {
        color: ${color('text-secondary-subdued')};
    }

    & input:disabled {
        -webkit-text-fill-color: ${color('text-secondary-subdued')};
        opacity: 1;
    }
`;
export const DeleteAccountButton = styled.div`
    button {
        padding: 10px;
        min-height: 44px;
    }
`;
