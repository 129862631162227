import config from 'config';

import UserApi from './components/User';
import Subscriptions from './components/Subscriptions';

import { ApiErrorData } from './types/response';

import ApiClient from './ApiClient';

function ApiInstance() {
    const errorHandlers: Array<(data: ApiErrorData) => void> = [];

    const onError = (fn: (errorData: ApiErrorData) => void) => {
        errorHandlers.push(fn);
    };

    const handleError = (errorData: ApiErrorData) => {
        errorHandlers.forEach((errorHandler) => errorHandler(errorData));
    };

    const apiClient = new ApiClient({ apiUrl: config.API_URL, onError: handleError });

    const user = new UserApi({ apiClient });
    const subscriptions = new Subscriptions({ apiClient });

    return {
        apiClient,
        user,
        subscriptions,
        onError,
    };
}

export default ApiInstance();
