import styled from 'styled-components';

import { StoreButton } from 'components/library';

export const Wrapper = styled.div`
    width: 216px;
    border-radius: 8px;
`;

export const StoreBtn = styled(StoreButton)<{ $isMobile: boolean }>`
    width: 216px !important;
    margin-bottom: ${(props) => props.$isMobile && '21px !important'};
    display: block;
`;
