import { IGuide } from 'types/guide';

export const bonusGuides: IGuide[] = [
    {
        internal_name: 'free_guide_how_to_start_your_morning',
        name: 'How to start your morning and be full of energy',
        is_purchased: false,
        download_url: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Walkfit(w)/How_To_Start_Your_Morning.pdf',
        preview: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Walkfit(w)/Cover/start-morning-136-px.webp',
    },
    {
        internal_name: 'free_guide_how_to_lose_weight_and_stay_fit',
        name: 'How to Lose Weight & Stay Fit',
        is_purchased: false,
        download_url: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Walkfit(w)/Lose_Weight&Stay_Fit.pdf',
        preview: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Walkfit(w)/Cover/lose-weight-stay-fit-136-px.webp',
    },
    {
        internal_name: 'free_guide_7_minute_workout_get_you_fit',
        name: 'The 7-Minute Workout That’ll Get You Fit',
        is_purchased: false,
        download_url: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Walkfit(w)/The_7-Minute_Workout.pdf',
        preview: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Walkfit(w)/Cover/7-minute-136-px.webp',
    },
    {
        internal_name: 'free_guide_ultimate_guide_to_sugar_influence_on_health',
        name: 'The ultimate guide to sugar influence on health',
        is_purchased: false,
        download_url: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Walkfit(w)/The_Sugar_Influence_On_Health.pdf',
        preview: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Walkfit(w)/Cover/sugar-136-px.webp',
    },
];
