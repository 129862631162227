import { ERROR_LEVELS, SENTRY_AXIOS } from 'sentry-utils';
import React, { useEffect, useState } from 'react';

import { ApiErrorData } from 'api/types/response';
import { FRONT_HASH_URL } from 'api/constants/urls';
import api from 'api';

import { logout } from 'store/auth/actions';

import sentry from 'services/Sentry/SentryInstance';

import { useDispatch } from 'hooks';

import GlobalErrorModal from 'modals/GlobalErrorModal';

const IGNORE_ERROR_STATUS_LISTS = [401, 403];

const ErrorModalContainer = () => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const dispatch = useDispatch();

    const handleErrorStatus = async ({ error, tmpOptions }: ApiErrorData) => {
        const responseURL = error?.response?.config?.url;
        const status = error?.response?.status;

        const isGetFrontHashSignatureMethod = responseURL?.startsWith(`/${FRONT_HASH_URL}`);

        if (status === 403 && !isGetFrontHashSignatureMethod) {
            await sentry.logError('403_error_logout', SENTRY_AXIOS, ERROR_LEVELS.ERROR, {
                reason: 'logout by 403 error',
                details: error,
                config: error?.config,
                responseConfig: error?.response?.config,
                response: error?.response,
                options: tmpOptions,
                token: localStorage.getItem('token'),
            });

            dispatch(logout());

            if (location.search) history.pushState(null, '', location.origin);

            location.reload();
        }

        if (status === 500 || status === 404) {
            setIsOpenModal(true);
        }

        if (IGNORE_ERROR_STATUS_LISTS.includes(status)) return;

        if (status >= 500) {
            sentry.logError(error, SENTRY_AXIOS, ERROR_LEVELS.CRITICAL, { ...tmpOptions, ...error?.response });
        }
    };

    useEffect(() => {
        api.onError(({ error, tmpOptions }: ApiErrorData) => {
            handleErrorStatus({ error, tmpOptions });
        });
    }, []);

    return <GlobalErrorModal isOpen={isOpenModal} />;
};

export default ErrorModalContainer;
