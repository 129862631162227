export const periodsList = [
    {
        title: 'basics.1week',
        shortKey: '1_week',
    },
    {
        title: 'basics.2weeks',
        shortKey: '2_weeks',
    },
    {
        title: 'basics.1month',
        shortKey: '1_month',
    },
    {
        title: 'basics.3month',
        shortKey: '3_months',
    },
];
