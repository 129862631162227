import { Trans } from 'react-i18next';
import React from 'react';

import { getSubscriptionTitle } from '../helpers';

import { Text } from 'components/library';

import * as S from './styled';

import { IPriceRow } from '../types';

const PriceRow = ({ period, price, color, subscriptionType }: IPriceRow) => {
    const paymentLabel = getSubscriptionTitle(period, false);

    return (
        <S.PriceRow>
            <Text type="medium-text" color={color}>
                <Trans
                    i18nKey="subscription.period"
                    values={{
                        count: paymentLabel?.count,
                        context: paymentLabel?.context,
                        subscriptionType,
                    }}
                />
            </Text>
            <Text type="medium-text" color={color}>
                {price}
            </Text>
        </S.PriceRow>
    );
};

export default PriceRow;
