import { useTranslation } from 'react-i18next';
import React from 'react';

import { setCancellationCandidate } from 'store/subscriptions/actions';
import { openModal, closeModal } from 'store/modals/actions';

import { useDispatch, useCancelSubscription } from 'hooks';

import AlertModal from 'modals/AlertModal';

import { ModalName } from 'types/modals';

const AlertModalContainer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { onCancelSubscription } = useCancelSubscription();

    const onSubmit = () => {
        dispatch(openModal(ModalName.WAITING_MODAL));

        onCancelSubscription();
    };

    const discardCancellationHandler = () => {
        dispatch(closeModal());

        dispatch(setCancellationCandidate(null));
    };

    return (
        <AlertModal
            onDiscard={discardCancellationHandler}
            onSubmit={onSubmit}
            captions={{
                title: t('subscription.freezePeriodAvailable.title'),
                description: t('subscription.freezePeriodAvailable.description'),
                submitButton: t('subscription.text.cancel'),
                discardButton: t('subscription.cancelDialog.discard'),
            }}
            withoutIcon
        />
    );
};

export default AlertModalContainer;
