import WaitingModalContainer from 'containers/modals/WaitingModalContainer';
import ReminderReasonModalContainer from 'containers/modals/ReminderReasonModalContainer';
import ReminderHasBeenSetModalContainer from 'containers/modals/ReminderHasBeenSetModalContainer';
import FreezeReasonModalContainer from 'containers/modals/FreezeReasonModalContainer';
import FreezePeriodModalContainer from 'containers/modals/FreezePeriodModalContainer';
import FreezeNotAvailableModalContainer from 'containers/modals/FreezeNotAvailableModalСontainer';
import FreezeAlertModalContainer from 'containers/modals/FreezeAlertModalContainer';
import ErrorModalContainer from 'containers/modals/ErrorModalContainer';
import DeleteAccountModalContainer from 'containers/modals/DeleteAccountModalContainer/DeleteAccountModalContainer';
import CancellationReasonModalContainer from 'containers/modals/CancellationReasonModalContainer';
import AlertModalContainer from 'containers/modals/AlertModalСontainer';
import AccountWasDeletedContainer from 'containers/modals/AccountWasDeletedSuccessContainer';
import AccountDeleteErrorContainer from 'containers/modals/AccountDeleteErrorContainer/AccountDeleteErrorContainer';

import { ModalName, ModalsType } from 'types/modals';

export const MODAL_LIST: ModalsType = {
    [ModalName.ALERT_MODAL]: AlertModalContainer,
    [ModalName.REASON_MODAL]: CancellationReasonModalContainer,
    [ModalName.REMINDER_MODAL]: ReminderReasonModalContainer,
    [ModalName.WAITING_MODAL]: WaitingModalContainer,
    [ModalName.FREEZE_ALERT_MODAL]: FreezeAlertModalContainer,
    [ModalName.FREEZE_REASON_MODAL]: FreezeReasonModalContainer,
    [ModalName.FREEZE_PERIOD_MODAL]: FreezePeriodModalContainer,
    [ModalName.FREEZE_NOT_AVAILABLE_MODAL]: FreezeNotAvailableModalContainer,
    [ModalName.REMINDER_HAS_BEEN_SET_MODAL]: ReminderHasBeenSetModalContainer,
    [ModalName.ERROR_MODAL]: ErrorModalContainer,
    [ModalName.DELETE_ACCOUNT]: DeleteAccountModalContainer,
    [ModalName.DELETE_ACCOUNT_SUCCESS]: AccountWasDeletedContainer,
    [ModalName.DELETE_ACCOUNT_ERROR]: AccountDeleteErrorContainer,
};
