import styled from 'styled-components';

import { Box } from 'components/library';

export const Container = styled(Box)`
    width: 100%;
    > * {
        background-color: transparent !important;
    }
`;
