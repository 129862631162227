import Login from 'pages/Login';
import InstallApp from 'pages/InstallApp';
import Guides from 'pages/Guides';
import ForgotPassword from 'pages/ForgotPassword';
import ChangePassword from 'pages/ChangePassword';
import ChangeEmail from 'pages/ChangeEmail';
import AuthorisedForgotPassword from 'pages/AuthorisedForgotPassword';
import AccountSettings from 'pages/AccountSettings';

import RootRedirect from '../RootRedirect';

export const PRIVATE = {
    ACCOUNT: {
        path: '/account/:tabId',
        component: AccountSettings,
        titleKey: 'pagesTitles.account',
    },
    ACCOUNT_DEFAULT: {
        path: '/account',
        component: AccountSettings,
        titleKey: 'pagesTitles.account',
    },
    ACCOUNT_PROFILE: {
        path: '/account/profile',
        component: AccountSettings,
        titleKey: 'pagesTitles.account',
    },
    CHANGE_EMAIL: {
        path: '/change-email',
        component: ChangeEmail,
        titleKey: 'pagesTitles.changeEmail',
    },
    CHANGE_PASSWORD: {
        path: '/change-password',
        component: ChangePassword,
        titleKey: 'pagesTitles.changePassword',
    },
    AUTHORISED_FORGOT_PASSWORD: {
        path: '/forgot-password-a',
        component: AuthorisedForgotPassword,
        titleKey: 'pagesTitles.forgotPassword',
    },
    MAIN: {
        path: '/walkfit-app',
        component: InstallApp,
        titleKey: 'pagesTitles.installApp',
    },
    FITNESS_GUIDES: {
        path: '/fitness-guides',
        component: Guides,
        titleKey: 'pagesTitles.guides',
    },
};

export const PUBLIC = {
    LOGIN: {
        path: '/log-in',
        component: Login,
        titleKey: 'pagesTitles.login',
    },
    FORGOT_PASSWORD: {
        path: '/forgot-password',
        component: ForgotPassword,
        titleKey: 'pagesTitles.forgotPassword',
    },
    DEFAULT: {
        path: '/',
        component: RootRedirect,
        titleKey: '',
    },
};

export default {
    PRIVATE,
    PUBLIC,
};
